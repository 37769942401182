import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';

import Spinner from './Spinner';
import { TickIcon, CrossIcon } from '../Icons';

import * as style from "./Buttons.module.scss";


import { useAsync } from './Hooks';
/*
    Progress Buttons
*/
type ProgressButtonProps = {
    children: React.ReactNode
    disabled?: boolean
    className?: string
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<void>
    [other: string]: any
}
const ProgressButton = ({ onClick, className, disabled, children, ...other }: ProgressButtonProps) => {
    const [ state, setState ] = useState('');
    const timerRef = useRef<NodeJS.Timeout>();

    const btnStateEles = {
        'p': <Spinner className={classNames(style.spin, style.ani)} />,
        't': <TickIcon className={classNames(style.tick, style.ani)} />,
        'c': <CrossIcon className={classNames(style.cross, style.ani)} />,
    }

    const clickFn = (e) => {
        onClick && setState('p');
        onClick && onClick(e)
            .then(() => setState('t'))
            .catch(() => setState('c'))
            .finally(() => (timerRef.current = setTimeout(() => setState(''), 2500)));
    };
    
    useEffect(() => { return () => clearTimeout(timerRef.current); }, []);

    return (
        <button 
            className={classNames(style.proButton, className, { [style.progress]: state !== ''})}
            onClick={clickFn} 
            disabled={disabled || state !== ''} 
            { ...other } >
            <div className={style.proButtonState}>
                { state in btnStateEles ? btnStateEles[state] : null }
            </div>
            <div className={style.proButtonContents}>{ children }</div>
        </button>
    )
}

type ProgressButtonProps2 = {
    disabled?: boolean
    className?: string
    onClick?: () => Promise<void>
    icon?: React.ReactNode
    text?: string
    pendingTxt?: string
    successTxt?: string
    errorTxt?: string
    [other: string]: any
}
const ProgressButton2 = ({ onClick, className, disabled, icon, text, pendingTxt, successTxt, errorTxt, ...other }: ProgressButtonProps2) => {
    const { execute, pending, value, error} = useAsync(onClick, 2500);

    return (
        <button 
            className={classNames(style.proButton, [style[className]])}
            onClick={execute} 
            disabled={disabled || pending || !!value || !!error} 
            { ...other } >

            <div className={style.proButtonState}>
                { pending && <>
                    <Spinner className={classNames(style.spin, style.ani)} />
                    { pendingTxt && <span className={style.pendingText}>{pendingTxt}</span> }</>}
                { value && <>
                    <TickIcon className={classNames(style.tick, style.ani)} />
                    { successTxt && <span className={style.successText}>{successTxt}</span> }</>}
                { error && <>
                    <CrossIcon className={classNames(style.cross, style.ani)} />
                    { errorTxt && <span className={style.errorText}>{errorTxt}</span> }</>}
            
            </div>

            { (!pending && !value && !error) && 
            <div className={style.proButtonContents}>
                { icon }
                <span className={style.text}>{text}</span>
            </div> }
        </button>
    )
}


// type ProgressFABProps = {
//     disabled?: boolean
//     className?: string
//     onClick?: () => Promise<void>

//     icon: React.ReactNode
//     pendingIcon?: React.ReactNode
//     successIcon?: React.ReactNode
//     errorIcon?: React.ReactNode

//     [other: string]: any
// }
// const ProgressFAB = ({ onClick, className, disabled, icon, pendingIcon=<CrossIcon/>, successIcon=<TickIcon/>, errorIcon=<CrossIcon/>, ...other }: ProgressFABProps) => {
//     const { execute, pending, value, error} = useAsync(onClick, 2500);


//     let i = icon;
//     if (error) i = errorIcon
//     else if (value) i = successIcon


//     return (
//         <button 
//             className={classNames(style.profab, [style[className]])}
//             onClick={execute} 
//             disabled={disabled || pending || !!value || !!error} 
//             { ...other } >
            
//             { pending && <Spinner className={classNames(style.spin, style.ani)} /> }

//             { i }

//         </button>
//     )
// }

const LinkButton = ({ children, classes, ...other }) => {
    return (
        <a 
            className={classNames(style.button, classes)}
            { ...other } >
            <div className={style.buttonContents}>{ children }</div>
        </a>
    );
}

type ButtonProps = {
    children: React.ReactNode
    disabled?: boolean
    classes?: string
    active?: boolean
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
    [other: string]: any
}
const Button = ({ children, classes, onClick, active, disabled, ...other }: ButtonProps) => {
    return (
        <button 
            className={classNames(style.button, classes, { [style.active]: active})}
            onClick={onClick} 
            disabled={disabled} 
            { ...other } >
            <div className={style.buttonContents}>{ children }</div>
        </button>
    );
}

export { Button, LinkButton, ProgressButton, ProgressButton2 };