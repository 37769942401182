import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { XYPlot, XAxis, YAxis, VerticalBarSeries } from 'react-vis';
import { AutoSizer } from 'react-virtualized';

import { TrackResult  } from '.././Types';
import { round } from '../../_Funcs';
import { ChevronLeftIcon, ChevronRightIcon } from '../../Icons';
import { colors, axisStyle, vertAxisStyle } from './Style';
import { Button } from '../../form/Buttons'

import style from './TrackGraph.module.scss';


enum DragStatus { Down, Up }

const yFuncs = {
    pnl: (d: TrackResult) => d.pnl, 
    avg: (d: TrackResult) => d.pnl / d.nm,
    to: (d: TrackResult) => d.to,
}

type GraphProps = {
    tracks: TrackResult[]
    yFunc: (t: TrackResult) => number
    width: number
    height: number
    selected: TrackResult
    setSelected: React.Dispatch<React.SetStateAction<TrackResult>>
    hintPos: number
    setHintPos: React.Dispatch<React.SetStateAction<number>>
    dragStatus: DragStatus
    setDragStatus: React.Dispatch<React.SetStateAction<DragStatus>>
    dragPos: number
    setDragPos: React.Dispatch<React.SetStateAction<number>>
    moveOffset: number
    setMoveOffset: React.Dispatch<React.SetStateAction<number>>
    baseOffset: number
    setBaseOffset: React.Dispatch<React.SetStateAction<number>>
}
const Graph = React.memo((props: GraphProps) => {
    const { tracks, yFunc, width, height, selected, setSelected, hintPos, setHintPos, dragStatus,
         setDragStatus, dragPos, setDragPos, moveOffset, setMoveOffset, baseOffset, setBaseOffset } = props;

    const n = Math.trunc(width / 35);
    const offset = Math.min(Math.max(moveOffset + baseOffset, 0), Math.max(tracks.length - n, 0));
    const tracksN = tracks.slice(offset, Math.min(n+offset, tracks.length));
    const getCol = (m: TrackResult) => selected && selected.trk === m.trk ? colors.blue: yFunc == yFuncs.to ? colors.yellow : m.pnl > 0 ? colors.green : colors.red;

    return (
        <>
            <XYPlot 
                className={classNames(style.graph, { [style.grabbing]: dragStatus == DragStatus.Down})}
                animation
                xType="ordinal"
                width={width}
                height={height}
                getX={d => d.trk }
                getY={ d => Math.abs(yFunc(d)) } 
                getColor={getCol} 
                onTouchStart={ e => { 
                    // e.preventDefault();
                    // const x = e.changedTouches[0].clientX;
                    // console.log(x);
                    // setDragStatus(DragStatus.Down);
                    // setDragPos(x);
                    // setSelected(null); 
                }}
                onTouchEnd={ e => { /*setDragStatus(DragStatus.Up); setBaseOffset(offset); setMoveOffset(0); setSelected(null); */}}
                onTouchMove={ e => { 
                    // e.preventDefault();

                    const x = e.changedTouches[0].clientX;
                    if (dragStatus == DragStatus.Down) {
                        const nextOffset = Math.trunc((dragPos-x) / 35);
                        // if (nextOffset != moveOffset) setMoveOffset(nextOffset);
                    }  
                }}
                
                onMouseLeave={ e => { setDragStatus(DragStatus.Up); setBaseOffset(offset); setMoveOffset(0); setSelected(null); } }
                onMouseUp={ e => { setDragStatus(DragStatus.Up); setBaseOffset(offset); setMoveOffset(0); setSelected(null); } }
                onMouseDown={ e => { setDragStatus(DragStatus.Down); setDragPos(e.clientX); setSelected(null); } }
                onMouseMove={ e => { 
                    if (dragStatus == DragStatus.Down) {
                        const nextOffset = Math.trunc((dragPos-e.clientX) / 35);
                        if (nextOffset != moveOffset) setMoveOffset(nextOffset);
                    }  
                }} >

                <VerticalBarSeries
                    data={ tracksN as any[] }
                    colorType='literal'
                    barWidth={undefined} 
                    onNearestX={ dragStatus == DragStatus.Up ? 
                        (i: any, p: any) => {
                            setSelected(i);
                            setHintPos(Math.min(Math.max(p.innerX, 70), width-60-70));
                        } :
                        undefined
                    }/>
                    

                <YAxis 
                    tickSize={0}
                    width={40}
                    style={axisStyle} /> 

                <XAxis 
                    tickSize={-15}
                    tickTotal={ tracksN.length }
                    tickLabelAngle={90}
                    marginLeft={35}
                    style={ vertAxisStyle } />


            </XYPlot>
            { selected &&
                <div className={style.hint}
                    style={{left: hintPos-90}}>
                    <div className={style.hintTitle}>{ selected.trk }</div>
                    <div className={style.row}>
                        <span className={style.text}>pnl:</span><span className={classNames(style.fig, { [style.red]: selected.pnl < 0, [style.green]: selected.pnl > 0 })}>{selected.pnl.toFixed(2)}</span>
                    </div>
                    <div className={style.row}>
                        <span className={style.text}>turnover:</span><span className={classNames(style.fig, style.yellow)}>{selected.to.toFixed(2)}</span>
                    </div>
                    <div className={style.row}>
                        <span className={style.text}>markets:</span><span className={style.fig}>{selected.nm }</span>
                    </div>
            </div> }
 
        </>
    );
});

type TrackGraphProps = {
    title: string
    tracks: TrackResult[]
}
export const TrackGraph = React.memo(({ tracks, title }: TrackGraphProps) => {
    const [ selected, setSelected ] = useState<TrackResult>(null);
    const [ hintPos, setHintPos ] = useState<number>(null)
    const [ dragStatus, setDragStatus ] = useState(DragStatus.Up);
    const [ dragPos, setDragPos ] = useState(0);
    const [ moveOffset, setMoveOffset ] = useState(0);
    const [ baseOffset, setBaseOffset ] = useState(0);
    const [ yFunc, setYFunc ] = useState(() => yFuncs.avg);
    const [ sortedTracks, setSortedTracks ] = useState(tracks); 
    let t = sortedTracks;
    useEffect(() => {
        t = tracks.sort((a, b) => yFunc(b) - yFunc(a));
        setSortedTracks(t);
    }, [ tracks ]);

    return (
        <div className={style.root} >
            <div className={style.toprow}>
                <div className={style.title}>{ title }</div>
                <div className={style.selects}>
                    <Button 
                        active={yFunc == yFuncs.pnl}
                        onClick={() => {
                            setYFunc(() => yFuncs.pnl)
                            setSortedTracks(tracks.sort((a, b) => yFuncs.pnl(b) - yFuncs.pnl(a)));
                        }
                    }>total</Button>  
                    <Button 
                        active={yFunc == yFuncs.avg}
                        onClick={() => {
                            setYFunc(() => yFuncs.avg)
                            setSortedTracks(tracks.sort((a, b) => yFuncs.avg(b) - yFuncs.avg(a)));
                        }
                    }>per market</Button>  
                    <Button 
                        active={yFunc == yFuncs.to}
                        onClick={() => {
                            setYFunc(() => yFuncs.to)
                            setSortedTracks(tracks.sort((a, b) => yFuncs.to(b) - yFuncs.to(a)));
                        }
                    }>turnover</Button>  
                </div>
                <div className={style.navButtons}>
                    <Button><ChevronLeftIcon/></Button>  
                    <Button><ChevronRightIcon/></Button>  
                </div>
            </div>
            <AutoSizer >
                { wh => 
                <Graph 
                    selected={selected}
                    setSelected={setSelected}
                    dragStatus={dragStatus}
                    setDragStatus={setDragStatus} 
                    dragPos={dragPos}
                    setDragPos={setDragPos} 
                    moveOffset={moveOffset}
                    setMoveOffset={setMoveOffset} 
                    baseOffset={baseOffset}
                    setBaseOffset={setBaseOffset} 
                    hintPos={hintPos}
                    setHintPos={setHintPos}
                    yFunc={yFunc}
                    tracks={t}
                    width={wh.width}
                    height={wh.height}
                />}
            </AutoSizer>
        </div>
    )
});