import { useState, useEffect, useCallback, useRef } from 'react';

export const useAsync = (asyncFunction: () => Promise<any>, timeout: number, immediate: boolean = false): { execute: () => Promise<void>, pending: boolean, value: any, error: any } => {
    const [ pending, setPending ] = useState(false);
    const [ value, setValue ] = useState<any>(null);
    const [ error, setError ] = useState(null);
    const timerRef = useRef<NodeJS.Timeout>();

    // The execute function wraps asyncFunction and
    // handles setting state for pending, value, and error.
    // useCallback ensures the below useEffect is not called
    // on every render, but only if asyncFunction changes.
    const execute = useCallback(() => {
        setPending(true);
        setValue(null);
        setError(null);
        return asyncFunction()
            .then(v =>  setValue(v ? v: true))
            .catch(setError)
            .finally(() => { 
                setPending(false);
                timeout && (timerRef.current = setTimeout(() => {
                    setValue(null);
                    setError(null);
                }, timeout));
            });
    }, [asyncFunction]);

    // Call execute if we want to fire it right away.
    // Otherwise execute can be called later, such as
    // in an onClick handler.
    useEffect(() => {
        if (immediate) {
            execute();
        }
    }, [execute, immediate]);

    useEffect(() => { return () => clearTimeout(timerRef.current); }, []);

    return { execute, pending, value, error };
};