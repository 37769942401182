import React, { useState } from 'react';
import classNames from 'classnames';
import InputRange, { Range } from 'react-input-range';
import moment from 'moment';
import { CrossIcon } from '../Icons';
import { roundTimeStamp, indexTick } from '../_Funcs';

import './HistoryRangeInput.scss';


function labelFn(n: number): string {
    const v  = indexTick(n);
    return v < 20 ? v.toFixed(2) : v.toFixed(0)
}


type RangeInputProps = {
    defaultValue: Range,
    range: Range,
    onChange: (r: Range) => void,
    step?: number
}
const RangeInput = ({defaultValue, range, onChange, step=1e4}: RangeInputProps) => {
    const [ value, setValue ] = useState(defaultValue || range);

    const atMin = value.min === range.min;
    const atMax = value.max === range.max;
    
    const change = v => onChange(v.min === range.min && v.max === range.max ? null : v);
    const resetFn = e => { e.stopPropagation(); setValue(range); change(range) }
    
    const label = atMin && atMax ? 
        <span>all prices</span> :
        <span>{ `${labelFn(value.min)} - ${labelFn(value.max)}` }<button onClick={resetFn} ><CrossIcon/></button></span>;

    return (
        <div className={classNames('history-range', { notDefault: !atMin||!atMax })}>
            <InputRange
                minValue={range.min}
                maxValue={range.max}
                value={value}
                step={step}
                allowSameValues
                onChange={ (r: Range) => setValue(r) } 
                onChangeComplete={change}
            />
            <div className='value'>{label}</div>                    
        </div>
    );
}


const sec = 1e3, min = 60*sec, hour = 60*min, day = 24*hour;
const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

/**
 * WARNING THERE BE DRAGONS
 * Note to future me - this component looks like it should be easy enough, but its a massive pain in the ass to 
 * get working perfectly. Every little nuance of it is very carefully considered now, after hours of fucking with it.
 * Dont try and optimize it. Dont fiddle. Leave the fucking thing along now that its working perfectly ffs.
 * Life is to short to touch this component again no matter what 'bright' idea you think you have. 
 * Turn around. Walk away. Or I'll cut you
 */
type DateRangeInputProps = {
    range: Range
    defaultValue: Range
    onChange: (r: Range) => void
}
class DateRangeInput extends React.PureComponent<DateRangeInputProps, {value: Range}> {
    offset = new Date().getTimezoneOffset()*60*1000;
    
    constructor(props) {
        super(props);
        this.state = { value: props.defaultValue };
    }
    setToday = () => { 
        const today = roundTimeStamp(new Date().getTime());
        const value = { min: today, max: today }
        this.setState({value}); 
        this.onChange(value);
    };
    setYesterday = () => { 
        const yesterday = roundTimeStamp(new Date().getTime() - day); 
        const value = { min: yesterday, max: yesterday }
        this.setState({value}); 
        this.onChange(value);
    };
    setWeek = () => { 
        const week = roundTimeStamp(new Date().getTime() - (7*day)); 
        const value = { min: week, max: this.props.range.max }
        this.setState({value}); 
        this.onChange(value);
    };
    setMonth = () => { 
        const monthStart = new Date();
        monthStart.setDate(1);
        monthStart.setHours(0, 0, 0, 0);
        
        const value = { min: monthStart.getTime() , max: this.props.range.max }
        this.setState({value}); 
        this.onChange(value);
    };
    set30Days = () => { 
        const week = roundTimeStamp(new Date().getTime() - (30*day)); 
        const value = { min: week, max: this.props.range.max }
        this.setState({value}); 
        this.onChange(value);
    };
    nextValue = (next: number, current: number) => {
        return next == current ? current : next > current ? next + this.offset : next + (day+this.offset);
    }
    guardVal = (v: Range, r: Range): Range => {
        const rangeMax = roundTimeStamp(r.max);
        return ({min:Math.max(Math.min(v.min, v.max, rangeMax), r.min-day), max:Math.min(Math.max(v.max, v.min), rangeMax)});
    }
    onUpdate = (v: Range) => {
        const value = this.state.value;
        if (value == undefined) {
            const min = v.min + this.offset;
            const max = v.max + (day+this.offset);
            this.setState({value: { min, max }});
            return
        }

        if (v.min == v.max) {
            const min = this.nextValue(v.min, value.min);
            const max = this.nextValue(v.max, value.max);

            if (min == value.min) {
                this.setState({ value: { min, max:min } })
            } else if (max == value.max) {
                this.setState({ value: { min:max, max } })
            }

        } else {
            const min = this.nextValue(v.min, value.min);
            const max = this.nextValue(v.max, value.max);
            this.setState({value: { min, max } });
        }
    }
    onChange = (v: Range) => {
        // console.log(new Date(v.min).toString(), new Date(v.max).toString())
        this.props.onChange(v);
    }

    render() {
        const onChange = this.props.onChange;
        const range = this.props.range? this.props.range : (now => ({min:now-7776e6, max:now}) )(new Date().getTime());
        const value = this.state.value? this.guardVal(this.state.value, range): range;

        const atMin = (value.min <= range.min+day-1), atMax = (value.max >= range.max-day-1);
        const resetFn = e => { e.stopPropagation(); this.setState({value: range}); onChange(range) }
        const DoMMM = (ts: number): string => {
            const d = new Date(ts);
            return d.getDate() + ' ' + months[d.getMonth()];
        }

        // label string value
        const label = atMin && atMax ? <span>all start dates</span> :
            value.min === value.max? <span>{DoMMM(value.min)}<button onClick={resetFn}><CrossIcon /></button></span>:
            <span>{`${DoMMM(value.min)} - ${DoMMM(value.max)}`}<button onClick={resetFn} ><CrossIcon/></button></span>

        // quick set button disable states
        const d = new Date();
        const today = roundTimeStamp(d.getTime());
        const todayDisabled = range.max < today;
        const yesterdayDisabled = range.max < (today-day);
        const weekDisabled = range.max < (today-(7*day));
        const _30Disabled = range.max < (today-(30*day));
        d.setDate(1); d.setHours(0, 0, 0, 0);
        const monthDisabled = range.max < d.getTime();

        return (
            <div className={classNames('history-range', { notDefault: !atMin||!atMax })}>
                
                <ul className={'quick-list'}>
                    <li><button disabled={todayDisabled} onClick={this.setToday}>today</button></li> 
                    <li><button disabled={yesterdayDisabled} onClick={this.setYesterday}>yesterday</button></li>
                    <li><button disabled={weekDisabled} onClick={this.setWeek}>week</button></li>
                    <li><button disabled={monthDisabled} onClick={this.setMonth}>month</button></li>
                    <li><button disabled={_30Disabled} onClick={this.set30Days}>30 days</button></li>
                </ul>
                 
                <InputRange
                    minValue={range.min-day}
                    maxValue={range.max+day}
                    allowSameValues
                    value={value}
                    step={day}
                    onChange={this.onUpdate} 
                    onChangeComplete={this.onChange}
                />
                
                <div className='value'>{label}</div>

                {/* can try and put this in later
                <input type="date" name="party" min="2017-04-01" max="2017-04-30" /> */}
            </div>
        );
    }
}

export { RangeInput, DateRangeInput };