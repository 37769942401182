import React from "react"
import { navigate } from "gatsby"

import { isLoggedIn, isBrowser } from '../components/_Funcs';
import History from '../components/history/History';
import Page from "../components/Page";

// import style from "./pnl.module.scss";

export default ({ location }) => {

    if (!isLoggedIn() && isBrowser()) {
        navigate('/login', { state: { refer: 'pnl' }});
        return null;
    }

    return (
        <Page location={location} subHeader={'Profit Loss'}>
            <History />
        </Page>
    );
}
