
export const round = n => Math.floor(n*100+0.5)/100;

export const isBrowser = (): boolean => (typeof window !== "undefined");

export const formatWithCommas = (x: number): string => 
    x?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const posPrefix = (n: number): '+' | '' => 
    n > 0 ? '+' : ''

export const getCookieValue = (a: string): string => {
    const b = document.cookie.match('(^|;)\\s*' + a + '\\s*=\\s*([^;]+)');
    return b ? b.pop() : '';
}

export const nextDiscountUpdate = (dt: number): number => {
    // discount updates at Sunday GMT midnight - we wanna get that
    // timestamp. we assume that GMT matches UTC here
    let d = new Date(dt);
    d.setUTCHours(0,0,0,0);
    return d.setUTCDate(d.getUTCDate() + (7-d.getUTCDay()));
}

export function roundTimeStamp(ts: number): number {
    let d = new Date(ts);
    d.setHours(0, 0, 0, 0);
    return d.getTime();
}

export const isLoggedIn = () => isBrowser() && !!getCookieValue('vid')



/**
 * A function that emits a side effect and does not return anything.
 */
export type Procedure = (...args: any[]) => void;
export type Options = {
  isImmediate: boolean,
}
export function debounce<F extends Procedure>(
    func: F,
    waitMilliseconds = 50,
    options: Options = { isImmediate: false },
): (this: ThisParameterType<F>, ...args: Parameters<F>) => void {
  
    let timeoutId: ReturnType<typeof setTimeout> | undefined;

    return function(this: ThisParameterType<F>, ...args: Parameters<F>) {
        const context = this;

        const doLater = () => {
            timeoutId = undefined;
            if (!options.isImmediate) {
                func.apply(context, args);
            }
        }

        const shouldCallNow = options.isImmediate && timeoutId === undefined;

        if (timeoutId !== undefined) {
            clearTimeout(timeoutId);
        }

        timeoutId = setTimeout(doLater, waitMilliseconds);

        if (shouldCallNow) {
            func.apply(context, args);
        }
    }
}


/*
    tickIndex returns what tick the price is, and what range its in
     ie 1.01 == [1, 0.01]
        1.05 == [5, 0.01]
        3.55 == [160, 0.05] 
        210 == [285, 10]
*/
export function tickIndex(price: number): [number, number] {
    // the + 0.00001 bullshit is cause node has pretty bad precision
    // ie 0.16 / 0.01 == 15.99999999999
    // this causes the floor to hit 15 - not 16
    
    if (price < 2) {
        return [ Math.floor(((price - 1) / 0.01) + 0.00001) + 0, 0.01 ];
    } else if (price < 3) {
        return [ Math.floor(((price - 2) / 0.02) + 0.00001) + 100, 0.02 ];
    } else if (price < 4) {
        return [ Math.floor(((price - 3) / 0.05) + 0.00001) + 150, 0.05 ];
	} else if (price < 6) {
        return [ Math.floor(((price - 4) / 0.1) + 0.00001) + 170, 0.1 ];
	} else if (price < 10) {
        return [ Math.floor(((price - 6) / 0.2) + 0.00001) + 190, 0.2 ];
	} else if (price < 20) {
        return [ Math.floor(((price - 10) / 0.5) + 0.00001) + 210, 0.5 ];
	} else if (price < 30) {
        return [ Math.floor(((price - 20) / 1) + 0.00001) + 230, 1 ];
	} else if (price < 50) {
        return [ Math.floor(((price - 30) / 2) + 0.00001) + 240, 2 ];
    } else if (price < 100) {
        return [ Math.floor(((price - 50) / 5) + 0.00001) + 250, 5 ];
	} else {
        return [ Math.floor(((price - 100) / 10) + 0.00001) + 260, 10 ];
	}
}

export function roundPrice(price: number): number {
    if (price < 2) {
        return Math.floor(price*(1/0.01)+0.5) / (1/0.01);
    } else if (price < 3) {
        return Math.floor(price*(1/0.02)+0.5) / (1/0.02);
    } else if (price < 4) {
        return Math.floor(price*(1/0.05)+0.5) / (1/0.05);
	} else if (price < 6) {
        return Math.floor(price*(1/0.1)+0.5) / (1/0.1);
	} else if (price < 10) {
        return Math.floor(price*(1/0.2)+0.5) / (1/0.2);
	} else if (price < 20) {
        return Math.floor(price*(1/0.5)+0.5) / (1/0.5);
	} else if (price < 30) {
        return Math.floor(price*(1/1)+0.5) / (1/1);
	} else if (price < 50) {
        return Math.floor(price*(1/2)+0.5) / (1/2);
    } else if (price < 100) {
        return Math.floor(price*(1/5)+0.5) / (1/5);
	} else {
        return Math.floor(price*(1/10)+0.5) / (1/10);
	}
}

// indexTick returns the price from the index
// ie 1 == 1.01
//    5 == 1.05
export function indexTick(i: number): number {
	if (i < 100) {
		return 1 + (i-0)*0.01
	} else if (i < 150) {
		return 2 + (i-100)*0.02
	} else if (i < 170) {
		return 3 + (i-150)*0.05
	} else if (i < 190) {
		return 4 + (i-170)*0.10
	} else if (i < 210) {
		return 6 + (i-190)*0.20
	} else if (i < 230) {
		return 10 + (i-210)*0.50
	} else if (i < 240) {
		return 20 + (i-230)*1
	} else if (i < 250) {
		return 30 + (i-240)*2
	} else if (i < 260) {
		return 50 + (i-250)*5
	} else {
		return 100 + (i-260)*10
	}
}

const monthLong = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
const monthShort = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

export const monthString = (m :number): string => monthLong[m];
export const monthStringShort = (m :number): string => monthShort[m];

export const dateWithSuffix = (d: number): string => {
    switch (d) {
        case 1:
        case 21:
        case 31:
            return d+"st";
        case 2:
        case 22:
            return d+"nd";
        case 3:
        case 23:
            return d+"rd";
        default:
            return d+"th";
    }
}
