import React from 'react';
import classNames from 'classnames';
import { RadialChart } from 'react-vis';

import { DowResult  } from './Types'
import { formatWithCommas, round, posPrefix } from '../_Funcs';
import { colors } from './graphs/Style'

import style from './HistoryComponents.module.scss';


type CalloutProps = {
    loading: boolean
    title: string
    children: React.ReactNode[] | React.ReactNode
}
const Callout = React.memo(({ loading, title, children }: CalloutProps) => {
    return (
        <div className={classNames(style.callout, { [style.loading]: loading })}>
            <div className={ style.title }>{ title }</div>            
            <div className={ style.container }>
                { children }
            </div>
        </div>
    );
});


type CommCalloutProps = {
    loading: boolean
    title: string
    pnl: number
    comm: number
    impl: number
    discounted: number
}
const CommCallout = React.memo(({ loading, title, pnl, comm, impl, discounted }: CommCalloutProps) => {
    return (
        <Callout title={title} loading={loading}>
            <div className={classNames(style.commission, { [style.loading]: loading })}>
                <div className={style.text}>

                    <div className={style.row}>
                        <span className={style.label}>Actual</span>
                        <span className={style.fig} style={{color: colors.yellow}}>{ !loading && round(comm) }</span>
                    </div>
                    <div className={style.row}>
                        <span className={style.label}>Implied</span>
                        <span className={style.fig} style={{color: colors.orange}}>{ !loading && (impl ? Math.abs(round(impl)): 0) }</span>
                    </div>
                    <div className={style.row}>
                        <span className={style.label}>Discounted</span>
                        <span className={style.fig} style={{color: colors.blue}}>{ !loading && (impl ? Math.abs(round(discounted)): 0) }</span>
                    </div>
                </div>
                <div className={style.graph}>
                    {/* <div>{marketData.markets.length}</div> */}
                    <RadialChart 
                        data={[
                            {label: 'pnl', angle: Math.abs(pnl), color: pnl > 0 ? colors.green : colors.red}, 
                            {label: 'commission', angle: Math.abs(comm), color: colors.yellow}, 
                            {label: 'implied', angle: Math.abs(impl), color: colors.orange},
                        ]}
                        innerRadius={25}
                        radius={35}
                        animation
                        colorType='literal'
                        width={90}
                        height={90}
                    />
                </div>
            </div>
        </Callout>
    );
});


type PnlCalloutProps = {
    loading: boolean
    title: string
    pnl: number
    nb: number
    nm: number
}
const PnlCallout = React.memo(({ loading, title, pnl, nb, nm }: PnlCalloutProps) => {
    return (
        <Callout title={title} loading={loading}>
            <div className={classNames(style.profit, { [style.green]: pnl > 0, [style.red]: pnl < 0, [style.loading]: loading}) }>
                <span className={style.fig}>{ !loading && posPrefix(pnl) + formatWithCommas(pnl) }</span>
                <span className={style.subfig}>{ !loading && `${formatWithCommas(pnl/nm)}/market` }</span>
                <span className={style.subfig}>{ !loading && `${formatWithCommas(pnl/nb)}/bet` }</span>
            </div>
        </Callout>
    )
});

type TurnOverCalloutProps = {
    loading: boolean
    title: string
    turnover: number
    nb: number
    nm: number
}
export const TurnOverCallout = React.memo(({ loading, title, turnover, nb, nm }: TurnOverCalloutProps) => {
    return (
        <Callout title={title} loading={loading}>
            <div className={classNames(style.turnover, { [style.loading]: loading })}>
                <span className={classNames(style.fig)}>{ !loading && formatWithCommas(turnover) }</span>
                <span className={classNames(style.subfig)}>{ !loading &&`${formatWithCommas(turnover/nm)}/market` }</span>
                <span className={classNames(style.subfig)}>{ !loading &&`${formatWithCommas(turnover/nb)}/bet` }</span>
            </div>
        </Callout>
    )
});


type CountCalloutProps = {
    loading: boolean
    title: string
    marketCount: number
    totalMarkets: number
    betCount: number
    totalBets: number
}
const CountCallout = React.memo(({ loading, title, marketCount, totalMarkets, betCount, totalBets}: CountCalloutProps) => {
    return (
        <Callout title={title} loading={loading}>
            <div className={classNames(style.count, { [style.loading]: loading })}>
                <div className={style.row}> 
                    <label>bets</label>
                    <span className={classNames(style.value, { [style.dim]: betCount >= totalBets }) }>{ !loading && betCount }</span> 
                </div>
                <div className={style.bar}>
                    <span className={classNames({ [style.dim]: betCount>=totalBets})} style={{width: (betCount/totalBets)*100+'%'}}></span>
                </div>

                <div className={style.row}> 
                    <label>markets</label>
                    <span className={classNames(style.value, { [style.dim]: marketCount >= totalMarkets }) }>{ !loading && marketCount }</span> 
                </div>
                <div className={style.bar}>
                    <span className={classNames({ [style.dim]: marketCount>=totalMarkets})} style={{width: (marketCount/totalMarkets)*100+'%'}}></span>
                </div>
            </div>
        </Callout>
    )
});




export { 
    CountCallout,
    CommCallout,
    PnlCallout,
};