import React, { useCallback } from 'react';
import classNames from 'classnames';
import FileSaver from 'file-saver';
import SwipeableViews from 'react-swipeable-views'
import MediaQuery from 'react-responsive'


import { fetchBetItems } from '../_Api';
import { ProgressButton2, Button } from '../form/Buttons';
import { ControlsIcon, RefreshIcon, GraphIcon, SettingsIcon, ListIcon, DashboardIcon } from '../Icons';

import HistoryFilter from './HistoryFilter';

import { getDB, deleteDB } from './HistoryDB'; 
import { nextDiscountUpdate, getCookieValue, isBrowser } from '../_Funcs';
import { HistoryList } from './HistoryList';
import {  Filter, FilterOptions, FilterResult, IDiscountRate, FilterConfig, HistoryListItemState, TurnoverCalc, WidthHeight } from './Types';
import { 
    CommCallout, 
    CountCallout,
    PnlCallout, 
    TurnOverCallout,
} from './HistoryComponents';
import { HistoryConfig, DropZone } from './HistoryConfig';
import { HistoryWelcome } from './HistoryWelcome';
import {  PNLGraph } from './graphs/PNLGraph';
import {  CandleStickGraph } from './graphs/CandleStickGraph';
// import {  DowGraph } from './graphs/DowGraph';
import {  TrackGraph } from './graphs/TrackGraph';
import {  GraphContainer} from './graphs/Container';
 
import style from './History.module.scss';

import worker from './HistoryWorker';

enum AsideView { Filter=1, Options=2, Data=3 }
enum MobileTab { Dashboard=0, List=1, Filter=2, Options=3 }

type HistoryState = {
    vcid: number
    loading: boolean
    asideView: AsideView
    mobileTab: MobileTab
    filter: {
        selected?: Filter,
        options?: FilterOptions,
    }
    filterCfg: FilterConfig
    data?: FilterResult
    list: { expanded: HistoryListItemState[] }
    count: [ number, number ] // [ betCount:number, marketCount:number ] in ts 4.0
}

class History extends React.Component<{}, HistoryState> {
    
    constructor(props) {
        super(props);

        let vcid: number;
        let cfg: FilterConfig;
        if(isBrowser()) {
            cfg = JSON.parse(localStorage.getItem('config'));
            vcid = Number(getCookieValue('vid'));
        }
        
        this.state = {
            vcid: vcid,
            loading: true,
            asideView: AsideView.Filter,
            mobileTab: MobileTab.Dashboard,
            filter: {
                options: null,
                selected: {},
            },
            filterCfg: cfg ?? { 
                overrideMbr: false,
                mbr: 0.05,
                overrideDr: false,
                dr: undefined,
                preferList: HistoryListItemState.Grouped,
                toCalc: TurnoverCalc.Volume,
            },
            
            list: {
                expanded: [],
            },
            count : [ undefined, undefined ],
            
            data: {
                markets: [],
                tracks: [],
                days: {
                    daysPnl: [],
                    dow: [],
                    dayIndex: [],
                },
                pnl: 0,
                turnover: 0,
                comm: 0,
                impComm: 0,
                discounted: 0,
                bets: 0,
            },
        }; 

        // ask for initial update
        isBrowser() && this.setTotalCount().then(([bc, mc]) => {
            if (mc > 0) this.runFilter();
        });
    }

    setTotalCount = async (): Promise<[number, number]> => {
        const db = getDB(this.state.vcid);

        const count = await Promise.all([
            db.bets.count(),
            db.markets.count(),
        ]);

        this.setState({ count });
        return count;
    };

    setConfig = (cfg: FilterConfig) => {
        isBrowser() && localStorage.setItem('config', JSON.stringify(cfg));
        this.setState(s => ({filterCfg: cfg}), this.runFilter);
    } 

    setListExpanded = (i: number, s: HistoryListItemState) => {
        const { list } = this.state;
        const expanded = (i !== null) ? ((list: HistoryListItemState[], i: number) => {
                let e = [ ...list ];
                e[i] = s;
                return e;
            })(list.expanded, i) : null;

        this.setState({ list: { ...list, expanded }});
    };

    setTab = (tab: MobileTab) => this.setState({ mobileTab: tab });
        
	setFilter = (filter: { options: FilterOptions, selected: Filter }) =>
        this.setState(s => ({ filter: filter }), this.runFilter);

    // setList = (list: { expanded: HistoryListItemState[] }, callback?: () => void) => 
    //     this.setState(s => ({ list: list }), callback);

    importJSONBlob = async (data: Blob, progress?: (progress) => boolean) => 
        worker.importJSONBlob(data, progress);

    startDownloadJSON = async (vcid: number, filename?: string) => 
        FileSaver.saveAs(await worker.exportJSONBlob(vcid), filename ?? `${vcid}.tarbot.json`);

    startDownloadCSV = async (vcid: number, filter: Filter, filename?: string) => 
        FileSaver.saveAs(await worker.exportCSVBlob(vcid, filter), filename ?? `${vcid}.tarbot.csv`);
    
    deleteDB = async (vcid: number) => {
        if (!isBrowser()) return Promise.reject();

        await deleteDB(vcid);
        await this.setTotalCount();
        await this.runFilter();
    }

    getMarketBets = async (mid: number) => {
        const bets = await getDB(this.state.vcid).bets.where({mid}).with({runner: 'sid'});
        return bets;
    };

    fetchBetItems = async (from: number, to: number, page: number, callback: () => void): Promise<number> => {
        return fetchBetItems(from, to, page)
            .then(async data => {
                if (Object.keys(data).length == 0) return 0;

                const db = getDB(this.state.vcid);
                await db.transaction('rw', db.markets, db.runners, db.eventTypes, db.events, db.bets, () => {
                    db.eventTypes.bulkPut(data.eventTypes);
                    db.events.bulkPut(data.events);
                    db.markets.bulkPut(data.markets);
                    db.runners.bulkPut(data.runners);
                    db.bets.bulkPut(data.bets);
                });

                // recalculate the discount rate table
                await db.transaction('rw', db.bets, db.rates, db.markets, () => {
                    db.rates.clear().then(() =>                   
                        db.bets.orderBy('st').with({market: 'mid'}).then( bets => {
                            // betfair updates discount rate at mignight sunday gmt
                            // if I see a change immediatley after a gmt sunday midnight
                            // I should assume it happened then and backdate the rate to then
                            // if it changes sometime midweek - I should assume some bf fuckery
                            // has happened and a vip/cs has added points/discount and try and 
                            // catch for it as normal. 
                            const rates: IDiscountRate[] = [];
                            let nextDrUpdate: number = 0; 

                            bets.forEach( bet => {
                                const pt = bet.market.mt+bet.prel;
                                const last = rates[rates.length-1];

                                if (pt > nextDrUpdate) {
                                    const ut = nextDrUpdate > 0 ? nextDrUpdate : pt;
                                    nextDrUpdate = nextDiscountUpdate(pt);

                                    if (bet.dr != last?.dr) {
                                        rates.push({pt: ut, dr: bet.dr});
                                        return;
                                    }
                                }

                                if (rates.length == 0 || last.dr != bet.dr) {
                                    rates.push({pt, dr: bet.dr});
                                }
                            });
                            return rates;
                        })
                   .then(rates => db.rates.bulkPut(rates)))
                });

                callback && await callback();

                if (data.more) { 
                    return data.bets.length + await this.fetchBetItems(from, to, page+1, callback);
                } else {
                    return data.bets.length;
                }
            })
            .catch(async err => Promise.reject(err));
    };
    
    updateItems = async () => {
        const db = getDB(this.state.vcid);
        const count = await db.bets.count();


        const sleep = async (ms: number) => {
            return new Promise(resolve => setTimeout(resolve, ms));
        }
          
        await sleep(10000);
        
        if (count > 0) {
            // search for all markets after latest and before first
            return Promise.all([
                db.bets.orderBy('st').last().then(last => 
                    this.fetchBetItems(last.st+1000, new Date().getTime(), 0, this.runFilter)),
                
                // db.bets.orderBy('st').first().then(first => {
                //     if (from < first.st) {
                //         return this.fetchBetItems(from, first.st-1, 0, this.runFilter);
                //     }
                // })
            ])
            .then(count => { this.setTotalCount() });
        } else {
            const from = new Date().getTime() - 7776e6; // 90 days

            // fetch all available markets
            return this.fetchBetItems(from, new Date().getTime(), 0, this.runFilter)
                .then(count => { this.setTotalCount() });
        }
    }
    
    runFilter = async () => {
        const { list, filter:{ selected }, filterCfg, vcid } = this.state;

        const { options, marketData } = await worker.getFilters(vcid, selected ? selected : null, filterCfg);
        this.setState({ loading: false, data: marketData, filter: { selected, options }, list:{ ...list, expanded: [] } });
    } 

    update = async () => {
        await Promise.all([
            this.setTotalCount(),
            this.runFilter(),
        ])
    }
	
	render() {
        const { loading, asideView, mobileTab, vcid, filterCfg, count, list, filter, data } = this.state;
        const [ betCount, marketCount ] = count;
        

        
        // const { daysPnl, dow } = data.days ?? {};


        return (
            marketCount === 0 ?
                <HistoryWelcome
                    importJSONBlob={this.importJSONBlob}
                    update={this.update}
                    fetchItems={this.updateItems} /> :
                 
            marketCount > 0 ?
			    <div id='root' className={style.root}>
                    
                    <MediaQuery minDeviceWidth={1025}>
                    { (desktopView: boolean) => desktopView ?
                    
                    <>
                        <Dashboard count={count} loading={loading} data={data} />
                        <div className={style.list}>
                            <HistoryList 
                                loading={loading}
                                filterConfig={filterCfg}
                                dayIndex={data.days.dayIndex}
                                markets={data.markets}
                                getMarketBets={this.getMarketBets}
                                expanded={list.expanded}
                                setExpanded={this.setListExpanded}
                            />
                        </div>
                        <div className={style.asideContainer}>
                            <div className={style.buttonBar}>
                                <ProgressButton2 
                                    onClick={this.updateItems} 
                                    pendingTxt={'updating'}
                                    successTxt={'done'}
                                    errorTxt={'error'}
                                    icon={<RefreshIcon />}
                                    text={'update'}
                                />
                                <div className={style.spacerFlex} />
                                <Button active={asideView == AsideView.Filter} onClick={() => this.setState({ asideView: AsideView.Filter })}>
                                    filter
                                </Button>
                                <div className={style.spacer} />
                                <Button active={asideView == AsideView.Options} onClick={() => this.setState({ asideView: AsideView.Options })}>
                                    <ControlsIcon />options
                                </Button>
                            </div>

                            { asideView == AsideView.Filter &&
                            <div className={style.filter}>
                                <HistoryFilter 
                                    options={filter.options}
                                    selected={filter.selected}
                                    setFilter={this.setFilter}
                                />
                            </div> }

                            { asideView == AsideView.Options &&
                            <div className={style.options} >
                                <HistoryConfig 
                                    config={filterCfg}
                                    vcid={vcid}
                                    filter={filter.selected}
                                    setConfig={this.setConfig}
                                    update={this.update}
                                    deleteDB={this.deleteDB}
                                    importJSONBlob={this.importJSONBlob}
                                    dlJSON={this.startDownloadJSON}
                                    dlCSV={this.startDownloadCSV}
                                />
                            </div> }
                        </div>

                    </> :
                    <>
                                    
                        <SwipeableViews animateHeight index={mobileTab} resistance={true} onChangeIndex={ tab => this.setState({ mobileTab: tab })} enableMouseEvents={true} >
                            <Dashboard count={count} loading={loading} data={data} />
                            <div className={style.list}>
                                <HistoryList 
                                    loading={loading}
                                    filterConfig={filterCfg}
                                    dayIndex={data.days.dayIndex}
                                    markets={data.markets}
                                    getMarketBets={this.getMarketBets}
                                    expanded={list.expanded}
                                    setExpanded={this.setListExpanded}
                                />
                            </div>
                            <div className={style.filter}>
                                <HistoryFilter 
                                    options={filter.options}
                                    selected={filter.selected}
                                    setFilter={this.setFilter}
                                />
                            </div>
                            <div className={style.options} >
                                <HistoryConfig 
                                    config={filterCfg}
                                    vcid={vcid}
                                    filter={filter.selected}
                                    setConfig={this.setConfig}
                                    update={this.update}
                                    deleteDB={this.deleteDB}
                                    importJSONBlob={this.importJSONBlob}
                                    dlJSON={this.startDownloadJSON}
                                    dlCSV={this.startDownloadCSV}
                                />
                            </div>
                        </SwipeableViews>
                
                        {/* <div className={style.mobileFab}><ProgressFAB onClick={this.updateItems} icon={<RefreshIcon />} /></div> */}
                        <MobileMenu tab={mobileTab} setTab={this.setTab} />
                    </> }
                </MediaQuery>
            </div> : 
            null
		);
	}
}

export default History;





type DashboardProps = {
    loading: boolean
    data: FilterResult
    count: [number, number]
}
const Dashboard = React.memo<DashboardProps>(({ loading, data, count:[betCount, marketCount] }: DashboardProps) => 
    <div className={style.dashboard}>
        <div className={style.calloutRow}>
            <CountCallout 
                loading={loading}
                title={'count'}
                marketCount={data.markets.length} 
                totalMarkets={marketCount} 
                betCount={data.bets} 
                totalBets={betCount} 
            />
            <TurnOverCallout
                loading={loading}
                title={'turnover'}
                nb={data.bets}
                nm={data.markets.length}
                turnover={data.turnover}
            />
            <PnlCallout 
                loading={loading} 
                title='profit' 
                pnl={data.pnl} 
                nb={data.bets} 
                nm={data.markets.length} 
            />
            <CommCallout 
                loading={loading} 
                title='commission' 
                pnl={data.pnl} 
                comm={data.comm} 
                impl={data.impComm} 
                discounted={data.discounted} 
            />
        </div>

        <GraphContainer 
            loading={loading}
            title={'Profit Loss'}
            items={[
                {label: 'markets', disabled: false, render: (props => <PNLGraph {...props} marketData={data} />)},
                {label: 'day', disabled: false, render: (props => <CandleStickGraph {...props} data={data.days.daysPnl} />)},
                // {label: 'Pnl vs BSP', disabled: false, title: 'Profit & Loss / Market VS BSP Profit & Loss', render: (props => <PNLvsSPGraph {...props} marketData={data} />)},
            ]}/>
    
        <TrackGraph title={'Track Profit Loss'} tracks={data.tracks} />
        {/* <DowGraph width={285} height={150} days={data.days.dow} /> */}
    </div>
)

type MobileMenuProps = {
    tab: MobileTab
    setTab: (tab: MobileTab) => void
}
const MobileMenu = React.memo<MobileMenuProps>(({ tab, setTab }: MobileMenuProps) => {
    const setDashboardTab = useCallback(() => setTab(MobileTab.Dashboard), []);
    const setListTab = useCallback(() => setTab(MobileTab.List), []);
    const setFilterTab = useCallback(() => setTab(MobileTab.Filter), []);
    const setOptionsTab = useCallback(() => setTab(MobileTab.Options), []);

    return (
        <div className={style.mobileMenu} >
            <div className={classNames(style.tab, { [style.active]: tab == MobileTab.Dashboard})}>
                <button onClick={setDashboardTab}><DashboardIcon />dashboard</button>
            </div>
            <div className={classNames(style.tab, { [style.active]: tab == MobileTab.List})}>
                <button onClick={setListTab}><ListIcon />list</button>
            </div>
            <div className={classNames(style.tab, { [style.active]: tab == MobileTab.Filter})}>
                <button onClick={setFilterTab}><ControlsIcon />filter</button>
            </div>
            <div className={classNames(style.tab, { [style.active]: tab == MobileTab.Options})}>
                <button onClick={setOptionsTab}><SettingsIcon />options </button>
            </div>
        </div>
    );
})
