import { HistoryResult, SubResult, AccountResult } from './_ApiTypes'
import { isBrowser } from '../components/_Funcs';

var origin = isBrowser() ? window.location.origin : ''; // 'localhost:8080';
const setOrigin = h => origin = h;

const fetchBetItems = async (from: number, to: number, page: number): Promise<HistoryResult> =>     
    fetch(`${origin}/history?f=${from}&t=${to}&p=${page}`)
    .then(response => {
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response;
    })
    .then(response => response.json());

const fetchAccount = async (): Promise<AccountResult> =>     
    fetch(`${origin}/account`)
    .then(response => {
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response;
    })
    .then(response => response.json());

const fetchSub = async (): Promise<SubResult> =>     
    fetch(`${origin}/substatus`)
    .then(response => {
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response;
    })
    .then(response => response.json());

export {
    setOrigin,
    fetchBetItems,
    fetchSub,
    fetchAccount,
}