import React from 'react';
import { AbstractSeries } from 'react-vis';

import { colors } from './Style';
  
export class CandleStickSeries extends AbstractSeries {
  render() {
    const { className, data, marginLeft, marginTop } = this.props;
    
    if (!data) { return null; }
      
    const xFunctor =  this._getAttributeFunctor('x');
    const yFunctor = this._getAttributeFunctor('y');
    const strokeFunctor = d => d.open < d.close ? colors.green : colors.red;
    const fillFunctor = d => d.open < d.close ? colors.green : colors.red;
    const opacityFunctor = this._getAttributeFunctor('opacity');
    const distance = data.length === 1 ? 4.2 : Math.abs(xFunctor(data[1]) - xFunctor(data[0])) * 0.22;
 
    return (
      <g
        className={className}
        transform={`translate(${marginLeft}, ${marginTop})`}>
        { data.map((d, i) => {
          const xTrans = xFunctor(d);
          const yHigh = yFunctor({...d, y: d.high});
          const yOpen = yFunctor({...d, y: d.open});
          const yClose = yFunctor({...d, y: d.close});
          const yLow = yFunctor({...d, y: d.low});

          const lineAttrs = {
            stroke: strokeFunctor && strokeFunctor(d),
            strokeWidth: 2,
          };

          const xWidth = distance * 2;
          return (
            <g
              transform={`translate(${xTrans})`}
              opacity={opacityFunctor ? opacityFunctor(d) : 1}
              key={i}
              onClick={e => this._valueClickHandler(d, e)}
              // onMouseOver={e => this._valueMouseOverHandler(d, e)}
              // onMouseOut={e => this._valueMouseOutHandler(d, e)}
            >
              <line
                x1={0}
                x2={0}
                y1={yHigh}
                y2={yHigh}
                {...lineAttrs}
              />
              <line x1={0} x2={0} y1={yHigh} y2={yLow} {...lineAttrs} />
              <line
                x1={0}
                x2={0}
                y1={yLow}
                y2={yLow}
                {...lineAttrs}
              />
              <rect
                x={-xWidth}
                width={Math.max(xWidth * 2, 0)}
                y={yOpen < yClose ? yOpen : yClose}
                height={Math.abs(yOpen - yClose)}
                fill={fillFunctor && fillFunctor(d)}
              />
            </g>
          );
        })}
      </g>
    );
  }
}
