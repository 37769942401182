import React from 'react';
import classNames from 'classnames';
import * as style from "./Spinner.module.scss";


/*
    Material circle spinner
*/
const Spinner = ({ className, ...other }) => {
    return (
        <svg className={classNames(style.spinner, className)} width="1em" height="1em" viewBox="0 0 66 66" { ...other }>
            <circle className={style.spinnerPath}
                fill="none" 
                stroke="currentColor" 
                strokeWidth="6" 
                strokeLinecap="round" 
                cx="33" cy="33" r="30" />
        </svg>
    )
};

export default Spinner;