import React from 'react';
import classNames from 'classnames';
import * as style from "./SwitchInput.module.scss";

/*
    Switch Input - material-ish switch
*/
type SwitchInputProps = {
    name?: string
    checked: boolean
    onChange: (event?: React.ChangeEvent<HTMLInputElement>) => void
    [other: string]: any
}

const SwitchInput = ({ name, checked, onChange, ...other }: SwitchInputProps) => 
    <label className={classNames(style.switchInput, { [style.blue]: other.blue })} { ...other }>
        <input type="checkbox" name={name} checked={checked} onChange={onChange} />
        <span className={style.switchInputBack} />
        <i className={style.switchInputHandle} />
    </label>

export default SwitchInput;