export type FilterValue<T = any> = {
	label: string
	value: T
	disabled: boolean
}

export type FilterRange = {
	min: number
	max: number
}

export type FilterOptions = {
	str: FilterValue<string>[],
	et: FilterValue<string>[],
	cc: FilterValue<string>[],
	trk: FilterValue<string>[],
	rlid: FilterValue<string>[],
	types: FilterValue<string>[],
	mbr: FilterValue<number>[],
	per: FilterValue<PersistenceType>[],
	ord: FilterValue<OrderType>[],
	mtRange: FilterRange
	dr: IDiscountRate[], 
} 

export interface IBetData extends IBet {
	eventType: IEventType
	event: IEvent
	market: IMarket
	runner: ISelection
}

export interface Filter {
	mt?: FilterRange
	price?: FilterRange
	s?: Side
	ip?: InPlay
	str?: string[]
	rlid?: string[]
	types?: string[]
	et?: string[]
	cc?: string[]
	trk?: string[]
	mbr?: number[]
	per?: PersistenceType[]
	ord?: OrderType[]
}

export type MarketResult = {
	index: number
	to: number
	pf: number
	pft: number
	sppf: number
	sppft: number
	comm: number
	discounted: number
	dr: number
	st: number
	market: IMarket,
	event: IEvent,
	eventType: IEventType
	bets: number[] // bet ids
	nsels: number
	sels: [number, Side][] // selection ids (with differing for backs and lays)
}

export type TrackResult = {
	nb: number		// num bets
	nm: number		// num markets
	pnl: number		// pnl / track
	to: number
	trk: string		// track
}

export type DayResult = {
	nb: number 		// num bets
	nm: number 		// num markets
	to: number 		// turnover
	date: number	// date
	open: number
	close: number
	low: number
	high: number
	x: number
	y: number
}
export type DowResult = {
	nb: number 		// num bets
	nm: number 		// num markets
	to: number 		// turnover
	d: number	// date
	pf: number
}

export type FilterResult = {
	markets: MarketResult[]
	tracks: TrackResult[]
	days: DayStats
	pnl: number
	turnover: number
	comm: number
	impComm: number
	discounted: number
	bets: number
}

export type DayStats = {
	dow: DowResult[] 	// day of week
	daysPnl: DayResult[] // change this to days later probs
	dayIndex: [number, number, number][] // [start, stop, timestamp] to index into the marketArray
}

export type FilterConfig = {
	overrideMbr: boolean 
	mbr: number
	overrideDr: boolean
	dr: number
	preferList: HistoryListItemState
	toCalc: TurnoverCalc
}

export interface IEventType {
    etid: number // event type id
    n: string    // event type name
}

export interface IEvent {
    eid: number  // event id
    n: string    // event name
    etid: number // event type id
    trk: string  // track/venue
    cc: string   // country code
}

export interface IMarket {
    mid: number // market id
    n: string   // market name
    eid: number // event id
    mt: number  // market time / market start time
    mbr: number // market base rate
    t: string   // market type
    bt: string  // market bet type
    nw: number  // num winners
    da: boolean // discount allowed
}

export interface ISelection {
    sid: number // selection id
    rid: number // runner id
    n: string   // selection name
}

export interface IBet {
	bid: number   // bet id
    eid: number   // event id
    etid: number  // event type id
    mid: number   // market id
    sid: number   // selection id
    prel: number  // placed time relative start time
    st: number    // settled time
    pm: number    // price matched
    pr: number    // price requested
    ss: number    // size settled
    pf: number    // profit
    dr: number    // discount rate
	sp: number  // selection sp
    rlid: string  // rule
    str: string   // strategy
    ip: InPlay    // inplay
    per: PersistenceType // persistence type
    ord: OrderType   	 // order type
    s: Side       // side
}

export interface IDiscountRate {
	pt: number
	dr: number
}

export interface WidthHeight { width: number, height: number }

export enum HistoryListItemState { Closed, Grouped, Detailed }
export enum TurnoverCalc { Volume=1, Liability=2 }

export enum Side { Back = 1, Lay = 2 }
export const SideStr = (s: Side): string => ["back", "lay"][s-1];
export enum PersistenceType { Lapse = 1, Persist = 2, MarketOnClose = 3 }
export const PersistenceTypeStr = (p: PersistenceType): string => ["Lapse", "Persist", "Market On Close"][p-1];
export enum OrderType { Limit = 1, LimitOnClose = 2, MarketOnClose = 3 }
export const OrderTypeStr = (o: OrderType): string => ["Limit", "Limit On Close", "Market On Close"][o-1];
export enum InPlay { InPlay = 1, PrePlay = 2 }
export const InPlayStr = (ip: InPlay): string => ["In-Play", "Pre-Play"][ip-1];

