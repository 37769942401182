import Dexie from 'dexie';
import relationships from 'dexie-relationships';
import {  IEventType, IEvent, ISelection, IMarket, IBetData, IDiscountRate } from './Types'
	 
class HistoryDB extends Dexie {
    public eventTypes: Dexie.Table<IEventType, number>;
    public events: Dexie.Table<IEvent, number>;
    public markets: Dexie.Table<IMarket, number>;
    public runners: Dexie.Table<ISelection, number>;  
    public bets: Dexie.Table<IBetData, number>;  
    public rates: Dexie.Table<IDiscountRate, number>;  

	constructor(vcid: number) {  
        super(String(vcid), { addons: [relationships] });

        this.version(1).stores({
            eventTypes: "etid, n",
            events: "eid, n, etid -> eventType.etid, trk, cc",
            markets: "mid, eid -> events.eid, mt, mbr, t, bt, da, nw",
            runners: "sid, rid, n",
            rates: "pt, dr",
            bets: `
                bid,
                etid -> eventTypes.etid,
                eid -> events.eid, 
                sid -> runners.sid, 
                mid -> markets.mid, 
                st, 
                pm, 
                pr, 
                ss, 
                pf, 
                dr, 
                ip, 
                rlid,
                prel, 
                str,
                per, 
                ord, 
                s, 
                sp`,
        });
	  
		this.eventTypes = this.table("eventTypes");
        this.events = this.table("events"); 
        this.markets = this.table("markets");
        this.runners = this.table("runners");
        this.bets = this.table("bets");
        this.rates = this.table("rates");
	}
}

const dbCache = new Map<number, HistoryDB>();
const getDB = (vcid: number): HistoryDB => {
    let db = dbCache.get(vcid);

    if (db == undefined) {
        db = new HistoryDB(vcid);
        db.open()
        .then(() => dbCache.set(vcid, db))
        .catch( e => console.error(`Open DB failed - vcid:${vcid} error:${e}`));
    }

    return db;
};

const deleteDB = async (vcid: number) => {
    let db = dbCache.get(vcid);
    
    if (db != undefined) {
        await Promise.allSettled([
            db.bets.clear(),
            db.eventTypes.clear(),
            db.markets.clear(),
            db.runners.clear(),
            db.rates.clear(),
            db.events.clear()
        ])
        db = new HistoryDB(vcid);
        db.open()
          .then(() => dbCache.set(vcid, db))
          .catch( e => console.error(`Open DB failed - vcid:${vcid} error:${e}`));
    }
} 

export {
    getDB,
    deleteDB,
    HistoryDB,
};

