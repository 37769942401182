import React from 'react';
import classNames from 'classnames';
import * as style from "./CheckBox.module.scss";

/*
    CheckBox - material-ish checkbox
*/
type CheckBoxProps = {
    name: string
    value: string
    label: string
    checked: boolean
    onChange: () => void
    onClick: () => void
    disabled?: boolean
    left?: boolean
    down?: boolean
    right?: boolean
    [other: string]: any
}
const CheckBox = ({name, value, label, checked, onChange, disabled, onClick, left, down, right, ...other }: CheckBoxProps) => 
    <label className={classNames(style.root, {[style.left]:left, [style.down]:down, [style.right]:right})} { ...other } >
        <input name={name} type="checkbox" value={value} disabled={disabled} checked={checked} onChange={onChange} onClick={onClick} />
        { label && <span className={style.rootLabel}>{label}</span> }
        <span className={style.rootBack}></span>
    </label>   
    
export default CheckBox;


