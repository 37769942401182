import React, { useState } from 'react';
import classNames from 'classnames';
import { XYPlot, XAxis, YAxis, LineSeries,  Crosshair } from 'react-vis';
import { FilterResult, MarketResult  } from '.././Types'
import { round } from '../../_Funcs';

import { colors, axisStyle } from './Style'
import style from './PNLGraph.module.scss';

type PNLGraphProps = {
    marketData: FilterResult
    width: number
    height: number
}
export const PNLGraph = React.memo(({ marketData, width, height }: PNLGraphProps) => {
    const [ closest, setClosest ] = useState<MarketResult>(null);
    const [ hintPos, setHintPos ] = useState<number>(null);

    return (
        <div className={style.root}>
            <XYPlot 
                animation 
                width={width}
                height={height}
                onMouseLeave={ () => setClosest(null) }
                getY={d => d.pft }
                getX={d => d.index+1 } >
                
        
                <YAxis 
                    tickSize={0}
                    width={40}
                    style={ axisStyle }
                />

                <XAxis 
                    tickTotal={0}
                    tickSize={0}
                    style={ axisStyle }
                />

                { /* need to dance around the type system here as our data doesnt have x and y properties - we use the getters */ }
                <LineSeries
                    data={ marketData.markets as any[]  }
                    getY={ mr => mr.pft }
                    getX={ mr => mr.index+1 }  
                    style={{ strokeLinejoin: 'round', strokeWidth: 2.4 }}
                    stroke={ colors.yellow }
                    curve='curveMonotoneX' // curveStepAfter
                    animation
                    onNearestX={ (mr: any, p: any) => {
                        setClosest(mr as MarketResult);
                        setHintPos(Math.min(Math.max(p.innerX, 130), width-110-70));
                    }}
                /> 

                {closest && 
                <Crosshair 
                    values={[ closest ]} 
                    style={{ line: { backgroundColor: colors.blue }}}>
                    <div />
                </Crosshair>}

            </XYPlot>
            { closest &&
                <div className={style.hint}
                    style={{left: hintPos-160}}>
                    <div className={style.title}>{closest.event.n + ' | '+ closest.market.n}</div>
                    <div className={style.row}>
                        <span className={style.text}>Market:</span><span className={classNames(style.fig, {[style.red]: closest.pf<0, [style.green]: closest.pf>0})}>{ round(closest.pf).toFixed(2)}</span>
                    </div>
                    <div className={style.row}>
                        <span className={style.text}>Total:</span><span className={classNames(style.fig, {[style.red]: closest.pft<0, [style.green]: closest.pft>0})}>{ round(closest.pft).toFixed(2)}</span>
                    </div>

                </div> }
        </div>
    );
});


/*
type PNLvsSPGraphProps = {
    marketData: FilterResult
    width: number
    height: number
}
const PNLvsSPGraph = React.memo(({ marketData, width, height }: PNLvsSPGraphProps) => {
    const [ closest, setClosest ] = useState<MarketResult>(null);

    return (
        <div className="pnlvsspgraph">
            <XYPlot 
                width={width}
                height={height}
                onMouseLeave={ () => setClosest(null) }
                getY={d => d.index%2===0 ?d.pft : d.sppft }
                getX={d => d.index+1 } >
        
                <YAxis animation 
                    tickSize={0}
                    width={40}
                    style={axisStyle}
                />

                <XAxis animation 
                    tickTotal={0}
                    tickSize={0}
                    style={axisStyle}
                />

                <LineSeries
                    animation
                    curve="curveStepAfter"
                    data={marketData.markets}
                    getY={ (mr: MarketResult) => mr.pft }
                    getX={ (mr: MarketResult) => mr.index+1 }  
                    style={{strokeLinejoin: "round", strokeWidth: 1.5}}
                    stroke={color.cyan}
                    onNearestX={ (mr: MarketResult) => setClosest(mr) }
                /> 

                <LineSeries
                    animation
                    curve="curveStepAfter"
                    data={marketData.markets}
                    getY={ (mr: MarketResult) => mr.sppft }
                    getX={ (mr: MarketResult) => mr.index+1 }  
                    style={{strokeLinejoin: "round", strokeWidth: 1.5}}
                    stroke={color.yellow}
                /> 

                {closest && 
                <Crosshair 
                    values={[closest]} 
                    style={{ line:{backgroundColor: color.magenta} }}>
                    <div />
                </Crosshair>}

            </XYPlot>
        
            { closest &&
            <div className='pnlgraph-hint'
                style={{width:width-50}}>
                <span className='pnlgraph-hint-name'>{closest.event.n + ' | '+ closest.market.n}</span>
                
                <span className='pnlgraph-hint-pf'>Profit:</span>
                <span className={classNames('fig', {red: closest.pft<0, green: closest.pft>0})}>{round(closest.pft)}</span>
                <span className={classNames('fig', {red: closest.pf<0, green: closest.pf>0})}>({round(closest.pf)})</span>
                
                <span className='pnlgraph-hint-sppf'>BSP Profit:</span>
                <span className={classNames('fig', {red: closest.sppft<0, green: closest.sppft>0})}>{round(closest.sppft)}</span>
                <span className={classNames('fig', {red: closest.sppf<0, green: closest.sppf>0})}>({round(closest.sppf)})</span>
                
            </div> }
        </div>
    );
});

*/