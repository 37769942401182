import React from 'react';
import classNames from 'classnames';
import * as style from "./Inputs.module.scss";

/*
    Text input with icon
*/
type TextInputProps = {
    onEnter: (event?: React.ChangeEvent<HTMLInputElement>) => void
    onChange: (event?: React.ChangeEvent<HTMLInputElement>) => void
    [ other: string]: any
}
const TextInput = ({  onChange, onEnter, value, ...other }: TextInputProps) => {
    const _handleKeyDown = (e) => { 
        if (onEnter && e.key === 'Enter') {
            e.target.blur();
            onEnter();
        }
    };

    return (
        <input className={classNames(style.textInput)} onChange={onChange} onKeyDown={_handleKeyDown} { ...other } />
    )
}

/*
    Select input with icon
*/
const SelectInput = ({ onChange, text, icon, children, ...other }) => {
    return (
        <label className={style.textInput}> 
            { icon && <span className={style.textInputIcon}>{ icon }</span> }
            { text && <span className={style.textInputText}>{ text }</span> }
            <select onChange={onChange} { ...other }>
                { children }
            </select>
        </label>
    )
}

export { TextInput, SelectInput };