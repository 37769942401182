import React, { useState, useCallback, useMemo } from 'react';
import Select from 'react-select';
import classNames from 'classnames';
import { useDropzone } from 'react-dropzone'

import { TextInput } from '../form/Inputs';
import SwitchInput from '../form/SwitchInput';
import Modal from '../form/Modal';
import { ProgressButton2, Button } from '../form/Buttons';
import RadioInput from '../form/RadioInput';
import { FilterConfig, Filter, HistoryListItemState, TurnoverCalc } from './Types';
import { debounce, isBrowser } from '../_Funcs';
import { CrossIcon, UploadIcon, GraphIcon } from '../Icons';

import style from './HistoryConfig.module.scss';

enum ModalType { Closed=0, Delete=1, Csv=2 }

const turnoverCalcLabel = (v: TurnoverCalc): {label:string, value: TurnoverCalc} => {
    switch (v) {
        case TurnoverCalc.Volume: return {label: 'Bet Size', value: v};
        case TurnoverCalc.Liability: return {label: 'Bet Liability', value: v};
    }
}
const HistoryListItemStateLabel = (v: HistoryListItemState): {label:string, value: HistoryListItemState} => {
    switch (v) {
        case HistoryListItemState.Grouped: return {label: 'Group Bets', value: v};
        case HistoryListItemState.Detailed: return {label: 'All Bets', value: v};
    }
}

type HistoryConfigProps = {
    config: FilterConfig
    setConfig: (cfg: FilterConfig) => void
    vcid: number
    filter: Filter
    update: () => void
    deleteDB: (vcid: number) => Promise<void> 
    dlJSON: (vcid: number) => void
    dlCSV: (vcid: number, filter: Filter) => void
    importJSONBlob: (data: Blob, progress?: (progress: any) => boolean) => Promise<any>
}
export const HistoryConfig = React.memo(({ config, setConfig, vcid, filter, update, importJSONBlob, deleteDB, dlJSON, dlCSV  }: HistoryConfigProps) => {
    const [ modal, setModal ] = useState<ModalType>(ModalType.Closed);

    const [ csvFilter, setCsvFilter ] = useState(true);

    const limitSetConfig = useCallback(debounce(setConfig, 500), [setConfig]);
    
    function onSelectChange<T>(option: { label: string, value: T }, meta: {name: string}) {
        setConfig({ ...config, [meta.name]: option.value});
    }

    return (
        <div className={style.root} >

            <Modal
                isOpen={modal != ModalType.Closed}
                onClose={() => setModal(ModalType.Closed)}
                className={style.deleteModal}
                label={modal == ModalType.Delete ? 'Delete Database': 'Create CSV'}>
                {modal == ModalType.Delete ? 
                    <div className={style.deleteModalBody}>
                        <h4 className={style.title}><CrossIcon /><span>Delete Database</span></h4>
                        <p className={style.text}>Do you want to delete all of the stored data for this user?</p>
                        <div className={style.buttons}>
                            <Button onClick={ () => setModal(ModalType.Closed)} >Cancel</Button>
                            <div className={style.spacer} />
                            <ProgressButton2 
                                className='red'
                                text='delete'
                                pendingTxt='deleting'
                                successTxt='deleted'
                                errorTxt='error'
                                onClick={ async () => deleteDB(vcid).then(() => setModal(ModalType.Closed) )} />
                        </div>
                    </div> :
                    <div className={style.deleteModalBody}>
                        <h4 className={style.title}><GraphIcon /><span>Create CSV</span></h4>
                        <p className={style.text}>Create and download a CSV file of your data</p>
                        <label className={style.row}>
                            <span className={style.text}>Use filter</span>
                            <SwitchInput 
                                checked={csvFilter}
                                onChange={ () => setCsvFilter(!csvFilter) }
                                blue={1}
                            />
                        </label>
                        <div className={style.buttons}>
                            <Button onClick={ () => setModal(ModalType.Closed)}>Cancel</Button>
                            <div className={style.spacer} />
                            <ProgressButton2 
                                className='blue'
                                text='download'
                                pendingTxt='downloading'
                                successTxt='done'
                                errorTxt='error'
                                onClick={ async () => dlCSV(vcid, csvFilter ? filter : {})} />
                        </div>
                        
                    </div>
                }
            </Modal>

            <label className={style.row}>
                <span className={style.text}>Market Base Rate Overide</span>
                <SwitchInput 
                    checked={config.overrideMbr}
                    onChange={ () => setConfig({ ...config, overrideMbr: !config.overrideMbr }) }
                    blue={1}
                />
            </label>
            <label className={classNames(style.optionRow, { [style.disabled]: !config.overrideMbr })}>
                <span className={style.text}>Rate</span>
                <TextInput 
                    onChange={ (event?: React.ChangeEvent<HTMLInputElement>) => {
                        const value = parseFloat(event.target.value) / 100;

                        if (value == NaN || !value ) limitSetConfig({ ...config, mbr: undefined })
                        else if (value > 1 ) limitSetConfig({ ...config, mbr: 1 }) 
                        else limitSetConfig({ ...config, mbr: value }) 
                    }}
                    onEnter={ () => {} }
                    placeholder={ config.overrideMbr ? (config.mbr && (config.mbr * 100).toPrecision(config.mbr > 0.1 ? 3: 2)) : '' }
                    type={'number'}
                    disabled={!config.overrideMbr}
                />
            </label>

            <label className={style.row}>
                <span className={style.text}>Discount Rate Overide</span>
                <SwitchInput 
                    checked={config.overrideDr}
                    onChange={ () => setConfig({ ...config, overrideDr: !config.overrideDr }) }
                    blue={1}
                />
            </label>
            <label className={classNames(style.optionRow, { [style.disabled]: !config.overrideDr })}>
                <span className={style.text}>Rate</span>
                <TextInput 
                    onChange={ (event?: React.ChangeEvent<HTMLInputElement>) => {
                        const value = parseFloat(event.target.value) / 100;

                        if (value == NaN || !value ) limitSetConfig({ ...config, dr: undefined })
                        else if (value > 1 ) limitSetConfig({ ...config, dr: 1 }) 
                        else limitSetConfig({ ...config, dr: value }) 
                    }}
                    onEnter={ () => {} }
                    placeholder={ config.overrideDr == true ? (config.dr && (config.dr * 100).toPrecision(2)) : '' }
                    type={'number'}
                    disabled={!config.overrideDr}
                />
            </label>

            <label className={style.selectRow}>
                <span className={style.text}>Turnover Method</span>

                <Select
                    className='filter-select'
                    classNamePrefix='filter-select'
                    placeholder='turnover formula'
                    onChange={onSelectChange}
                    isOptionDisabled={false}
                    maxMenuHeight={340}
                    name='toCalc'
                    isSearchable={false}
                    value={turnoverCalcLabel(config.toCalc)}
                    options={[
                        turnoverCalcLabel(TurnoverCalc.Liability),
                        turnoverCalcLabel(TurnoverCalc.Volume),
                    ]}/>
            </label>

            <label className={style.selectRow}>
                <span className={style.text}>Default List View</span>
                <Select
                    className='filter-select'
                    classNamePrefix='filter-select'
                    placeholder='list view'
                    onChange={ onSelectChange }
                    isOptionDisabled={false}
                    maxMenuHeight={340}
                    isSearchable={false}
                    name='preferList'
                    value={ HistoryListItemStateLabel(config.preferList)}
                    options={[
                        HistoryListItemStateLabel(HistoryListItemState.Detailed),
                        HistoryListItemStateLabel(HistoryListItemState.Grouped),
                    ]}/>
            </label>

            <div className={style.spacer} />

            <div className={style.dexie} >
                <span className={style.text}>Manage Database</span>
                <DropZone importJSONBlob={importJSONBlob} update={update} 
                    hint={<p className={style.hint}>Click or drop backup file to import</p>}
                    done={<p className={style.hint}>imported</p>} />
                <div className={style.row}>
                    <Button onClick={() => setModal(ModalType.Csv) }>Create CSV</Button>
                    <div className={style.spacer} />
                    <ProgressButton2 
                        text='backup'
                        pendingTxt='exporting'
                        successTxt='exported'
                        errorTxt='error'
                        onClick={ async () => dlJSON(vcid)}/>
                    <div className={style.spacer} />
                    <Button onClick={ () => setModal(ModalType.Delete)}>Delete</Button>
                </div>
            </div>

        </div>
    );
})

type DropZoneProps = {
    hint: React.ReactNode
    done: React.ReactNode
    importJSONBlob: (data: Blob, progress?: (progress: any) => boolean) => Promise<any>
    update: () => void
}
export const DropZone = ({ hint, done, importJSONBlob, update }: DropZoneProps) => {
    const [ fileName, setFileName ] = useState('');
    const [ uploading, setUploading ] = useState(false);

    const onDrop = useCallback((acceptedFiles: File[]) => {
        if (!isBrowser()) return;

        acceptedFiles.forEach(async (file: File) => {
            const reader = new FileReader();
            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = async () => {
                setFileName(file.name);
                setUploading(true);

                try {
                    const data = new Blob([ reader.result ]);
                    // cant use the callback while importJSONBlob is run in the webworker
                    await importJSONBlob(data, /*p => {
                        if (p.done) {
                            setUploading(false);
                            setUploadPct(100);
                        } else {
                            const pct = Math.floor((p.completedRows / p.totalRows)*100);
                            pct != uploadPct && setUploadPct(pct);
                        }
                        return true;
                    }*/);
                    setUploading(false);

                    update();
                } catch(err) { console.log(err) }
            }

            reader.readAsArrayBuffer(file);
        })
    }, []);

    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({onDrop, accept: 'application/json'})
    const rootStyle = useMemo(() => ({ [style.dragActive]: isDragActive, [style.dragAccept]: isDragAccept, [style.dragReject]: isDragReject}), [isDragActive, isDragAccept, isDragReject]);

    return (
        <div { ...getRootProps()} className={ classNames(style.dropZone, rootStyle )}>
            <input {...getInputProps()} />
            { uploading ? done : hint }
            <div className={style.background}><UploadIcon /></div>
        </div>)
}
