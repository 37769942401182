import React, { useRef, useCallback, useEffect } from "react";
import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';
import { VariableSizeList  } from "react-window";
// import { Scrollbars } from "react-custom-scrollbars";
import { AutoSizer } from 'react-virtualized';
import { Side, MarketResult, IBetData, WidthHeight, FilterConfig, HistoryListItemState } from './Types'
import { ChevronDownIcon, LowPriorityIcon } from '../Icons';
import { Button } from '../form/Buttons';
import moment from "moment";
import { round } from '../_Funcs';

import css from './HistoryList.module.scss';


type HistoryListProps = {
    expanded: HistoryListItemState[]
    setExpanded: (i: number, s: HistoryListItemState) => void
    dayIndex: [number, number, number][]
    markets: MarketResult[]
    getMarketBets: (mid: number) => Promise<IBetData[]>
    filterConfig: FilterConfig
    loading: boolean
}

const HistoryList = ({ loading, expanded, setExpanded, dayIndex, markets, getMarketBets, filterConfig }: HistoryListProps) => {
    const isDesktop = useMediaQuery({ minDeviceWidth: 1025 });
    const isNarrow = useMediaQuery({ minWidth: 650 });
    
    const listRef = useRef(null);
    useEffect(() => {
        listRef.current.resetAfterIndex(0);
    }, [expanded]);

    const marketsWithDays: (MarketResult|Date)[] = [];
    dayIndex.forEach( ([start, stop, ts]) => {
        marketsWithDays.push(...markets.slice(start, stop));
        marketsWithDays.push(new Date(ts));
    });

    const [ _Row, itemCount, itemData, keyFn, sizeFn ] = /* loading ? loadingVals : */
        [ 
            Row, 
            marketsWithDays.length, 
            { 
                marketsWithDays, getMarketBets, filterConfig, expanded, 
                setExpanded: (i: number, s: HistoryListItemState) => { 
                    setExpanded(marketsWithDays.length-i-1, s); 
                    listRef.current.resetAfterIndex(0);
                }
            },
            (i, d) => {
                const rIndex = d.marketsWithDays.length - i - 1;
                const item = d.marketsWithDays[rIndex];

                return item instanceof Date ? item.getTime() : item.market.mid;
                // return d.marketsWithDays[rIndex] ? d.markets[rIndex].market.mid : rIndex;
                // return d.markets.length - i - 1;
            },
            i => {
                const ri = marketsWithDays.length-i-1;
                const item = marketsWithDays[ri];
                const exp = expanded[ri];

                if (item instanceof Date) {
                    return 40;
                } else if (exp == undefined || exp  === HistoryListItemState.Closed) {
                    return 50;
                } else if (exp === HistoryListItemState.Grouped) {
                    return isDesktop ? (item.nsels*30) + 310 : (item.nsels*30) + 405;
                } else if (exp === HistoryListItemState.Detailed) {
                    return isDesktop ? (item.bets.length*30) + 320 : (item.bets.length*(isNarrow?30:50)) + 405;
                }
            }
        ];

        return (
            <>
                { isDesktop && <div className={css.header}>
                    <div className={css.market}> market </div>
                    <div className={css.time}> start time</div>
                    <div className={css.profit}> profit </div>
                </div> }
                <div className={css.list}>
                    <AutoSizer>
                        {({ height, width }: WidthHeight) => (
                        <VariableSizeList 
                            overscanCount={12}
                            ref={listRef}
                            height={height}
                            width={width}
                            itemCount={itemCount}
                            itemKey={keyFn}
                            itemSize={sizeFn}
                            itemData={itemData} >
                            {_Row}
                        </VariableSizeList >
                        )}
                    </AutoSizer>
                </div>
            </>
        );  
}

// use index to reverse index the markets
type RowProps = {
    data: {
        filterConfig: FilterConfig
        grouped: boolean
        marketsWithDays: (MarketResult|Date)[]
        getMarketBets: (mid: number) => Promise<IBetData[]>
        selected: number
        setSelected: (i: number) => void
        expanded: HistoryListItemState[]
        setExpanded: (i: number, s: HistoryListItemState) => void
    }
    index: number
    style: any
}
const Row = React.memo((props: RowProps) => {
    const { data:{marketsWithDays, grouped, getMarketBets, expanded, setExpanded, filterConfig}, index, style} = props;
    const rIndex = marketsWithDays.length - index - 1;
    const item = marketsWithDays[rIndex];
    const exp = expanded[rIndex];
    const setExpandedCb = s => setExpanded(index, s);

    if (item instanceof Date) {
        return (
            <DateHeaderRow date={item} style={style} />
        );
            
    } else {
        return (
            <HistoryRow 
                filterConfig={filterConfig}
                market={item}
                getMarketBets={getMarketBets}
                style={style} 
                grouped={grouped}
                expanded={exp}
                setExpanded={setExpandedCb}
            />);
    }

});

type Sel = {
    sid: number
    name: string
    side: Side
    sp: number
    profit: number
    price: number
    vol: number
    n: number
    fn: number
}
type HistoryRowProps = {
    expanded: HistoryListItemState
    setExpanded: (s: HistoryListItemState) => void
    grouped: boolean
    style: any
    getMarketBets: (mid: number) => Promise<IBetData[]>
    market: MarketResult
    filterConfig: FilterConfig
}
type HistoryRowState = {
    bpnl: number
    lpnl: number
    mpnl: number
    nf: number
    sels: Sel[],
    bets: IBetData[],
    loading: boolean
}
class HistoryRow extends React.Component<HistoryRowProps, HistoryRowState> {
    state: HistoryRowState = {
        bpnl: 0,
        lpnl: 0,
        mpnl: 0,
        nf: 0,
        sels: [],
        bets: [],
        loading: false,
    };

    // componentWillReceiveProps(nextProps: HistoryRowProps) {
    //     if (this.props.market.market.mid != nextProps.market.market.mid) {
    //         this.setState({
    //             bpnl: 0,
    //             lpnl: 0,
    //             mpnl: 0,
    //             nf: 0,
    //             sels: [],
    //             bets: [],
    //             loading: false,
    //         })
    //     }
    // }

    constructor(props) {
        super(props);

        const { expanded } = this.props;
        if ( expanded != undefined && expanded != HistoryListItemState.Closed ) {
            this.getBets();
        }
    }

    onExpanded = e => {
        e.stopPropagation();
        this.getBets();

        const { setExpanded, filterConfig} = this.props;

        const exp = this.props.expanded;
        if (exp == undefined || exp == HistoryListItemState.Closed) {
            setExpanded(filterConfig.preferList);
        } else {
            setExpanded(HistoryListItemState.Closed);
        }
    }

    showDetails = e => {
        e.stopPropagation();

        const exp = this.props.expanded;
        if (exp ==  HistoryListItemState.Grouped) {
            this.props.setExpanded(HistoryListItemState.Detailed);
        } else {
            this.props.setExpanded(HistoryListItemState.Grouped);
        }
    }

    getBets = async () => {
        const { bets, loading } = this.state;

        if (bets.length === 0 && !loading) {
            this.setState({loading: true});
            
            const { getMarketBets, market:{bets:bids, market:{mid}}} = this.props;
            const bs = await getMarketBets(mid);
            const inFilter = new Set(bids);
            const filteredBets = [];
            const sels: { [id: number]: Sel } = {};
            let bpnl = 0, lpnl = 0;
            
            for (let i = 0; i < bs.length; i++) {
                const b = bs[i]; 
                
                if (inFilter.has(b.bid)) {
                    let sel = sels[b.sid+b.s];
                    if (sel === undefined) {
                        sel = sels[b.sid+b.s] = { sid:b.sid+b.s, name:b.runner.n, side:b.s, sp:b.sp,
                             profit:0, price:0, vol:0, n:0, fn:0 }
                    }

                    
                    if (b.s === Side.Back) {
                        bpnl += b.pf;
                    } else {
                        lpnl += b.pf;
                    }

                    // calculate the selection stats
                    sel.n++;
                    sel.profit += b.pf;
                    sel.price = ((sel.price * sel.vol) + (b.pm * b.ss)) / (sel.vol+b.ss);
                    sel.vol += b.ss;

                    filteredBets.push(b);
                } 
            }

            this.setState({ 
                lpnl, bpnl,
                mpnl: bpnl+lpnl,
                nf: bs.length - filteredBets.length,
                bets: filteredBets.sort((a,b) => (a.runner.n > b.runner.n) ? 1 : ((b.runner.n > a.runner.n) ? -1 : 0)),
                sels: Object.values(sels).sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : a.side === Side.Back ? 1 : 0)),
                loading: false,
            });
        }
    }

    render() {
        const { style, market:m, grouped, expanded, filterConfig } = this.props;
        const { pf, dr, discounted, comm, st, nsels, bets:bids, market, event, eventType } = m;
        const { bpnl, lpnl, mpnl, nf, sels, bets } = this.state;

        return (
            <div className={classNames(css.row, { [css.expanded] : expanded })} style={{...style, height: style.height-(expanded?20:0) }}>
                <div className={css.outside} onClick={this.onExpanded}>
                    <div className={css.name}>
                        <div className={css.nameType}>{`${eventType.n}`}</div>
                        <div className={css.nameEvent}>{`${event.n} | ${market.n}`}</div>
                    </div>

                    { (expanded == undefined || expanded == HistoryListItemState.Closed) ? <>
                    <div className={css.time}> <span>{moment(market.mt).format('h:mm A')}</span></div> 
                    <div className={classNames(css.profit, { [css.green]: pf>0, [css.red]: pf<0})}><span>{round(pf).toFixed(2)}</span></div>
                    </> :
                    <div className={css.groupBtn}>
                        <Button  onClick={this.showDetails}>
                            { expanded == HistoryListItemState.Detailed ? 'group bets' : 'all bets'}
                        </Button>
                    </div>
                    }

                    <div className={css.chevron}><ChevronDownIcon /></div>
                </div>
                
                { expanded != undefined && expanded != HistoryListItemState.Closed && 
                <div className={css.inside}>
                    { expanded == HistoryListItemState.Detailed ? 
                        <>
                            <BetRowHeader />
                            {bets.map(b => <BetRow key={b.bid} bet={b} time={market.mt} /> )}
                        </> :
                        /* expanded == HistoryListItemState.Open */
                        <> 
                            <SelRowHeader />
                            {sels.map(s => <SelRow key={s.sid} sel={s} /> )}
                        </> 
                    }

                    <div className={css.summary} >
                        <div className={css.info}>
                            <div className={classNames(css.infoFiltered, { [css.hidden]:  nf == 0})}> {nf} filtered bets</div>
                            <div><span className={css.infoLabel}>Market Time: </span><span className={css.infoValue}>{moment(market.mt).format('lll')}</span></div>
                            <div><span className={css.infoLabel}>Settled Time:</span><span className={css.infoValue}>{moment(st).format('lll')}</span></div>
                        </div>
                        <div className={css.innerSummary}>
                            <div className={css.text}>
                                <div>Back subtotal:</div>
                                <div>Lay subtotal:</div>
                                <div>Market subtotal:</div>
                                <div>Commission @ { (filterConfig.overrideMbr && filterConfig?.mbr != undefined) ? 
                                    <><s>{market.mbr}%</s><span className={css.textFiltered}>{(filterConfig.mbr*100).toFixed(2)+'%'}</span></> : market.mbr+'%'}:
                                </div>
                                <div>Commission after discount @ { (filterConfig.overrideDr && filterConfig?.dr != undefined) ?
                                    <><s>{(dr*100)}%</s><span className={css.textFiltered}>{(filterConfig.dr*100).toFixed(0)+'%'}</span></> : dr+'%'}:
                                </div>
                                <div>Net Market Total:</div>
                            </div>
                            <div className={css.figures}>
                                <div className={classNames({ [css.green]: bpnl>0, [css.red]: bpnl<0})}>{round(bpnl).toFixed(2)}</div>
                                <div className={classNames({ [css.green]: lpnl>0, [css.red]: lpnl<0})}>{round(lpnl).toFixed(2)}</div>
                                <div className={classNames({ [css.green]: mpnl>0, [css.red]: mpnl<0})}>{round(mpnl).toFixed(2)}</div>
                                <div className={css.yellow}>{(comm < 0 ? 0 : round(comm)).toFixed(2)}</div>
                                <div className={css.orange}>{ round(comm - discounted).toFixed(2) }</div>
                                <div className={classNames(css.border, {[css.green]: pf>0, [css.red]: pf<0})}>{round(pf).toFixed(2)}</div>
                            </div>
                        </div>
                    </div>
                </div> }


            </div>
        );
    }
}

const LoadingRow = ({ style }) => 
    <div className={classNames(css.row, css.loading)} style={style}>
        <div className={css.outside}>
            <div className={css.name}>
                <div className={css.nameType}></div>
                <div className={css.nameEvent}></div>
            </div>
            <div className={css.time}><span></span></div>
            <div className={css.profit}><span></span></div>
            <div className={css.chevron}><ChevronDownIcon /></div>
        </div>
    </div>

const BetRowHeader = () => 
    <div className={css.betHeader}>
        <div className={css.name}> selection </div>
        <div className={css.strategy}> strategy </div>
        <div className={css.rule}> rule </div>
        <div className={css.time}> placed  </div>
        <div className={css.side}> side </div>
        <div className={css.price}> odds  </div>
        <div className={css.volume}> stake  </div>
        <div className={css.profit}> profit </div>
    </div>

const SelRowHeader = () => 
    <div className={css.selHeader}>
        <div className={css.name}> selection </div>
        <div className={css.side}> side </div>
        <div className={css.count}> count </div>
        <div className={css.sp}> bsp </div>
        <div className={css.price}> wap  </div>
        <div className={css.volume}> stake  </div>
        <div className={css.profit}> profit </div>
    </div>

type BetRowProps = {
    bet: IBetData
    time: number
}
const BetRow = ({ time, bet:{ runner:{n: name}, pf, pm, pr, ss, rlid, str, s, prel, ord, per }}: BetRowProps) => {
    return (
        <div className={css.betRow}>
            <div className={css.name}>
                {name}
            </div>
            <div className={css.strategy}>
                <span>{str}</span>
            </div>
            <div className={css.rule}>
                <span>{rlid}</span>
            </div>
            <div className={css.time}>{moment(time + prel).format('h:mm:ss A')}</div>
            <div className={css.side}>
                <span className={classNames({ [css.back]: s === Side.Back, [css.lay]: s === Side.Lay})}>
                    {s === 1 ? 'BACK': 'LAY'}
                </span>
            </div>
            <div className={css.price}>{pm.toFixed(2) + (pm !== pr ? ` (${pr.toFixed(2)})`: '')}</div>
            <div className={css.volume}>{ss.toFixed(2)}</div>
            <div className={classNames(css.profit, { [css.green]: pf>0, [css.red]: pf<0})}>
                {pf.toFixed(2)}
            </div>
        </div>
    );
}

type SelRowProps = {
    sel: Sel
}
const SelRow = ({ sel: { name, side, sp, profit, price, vol, n, fn } }: SelRowProps) => {
    return (
        <div className={classNames(css.selRow, { [css.filtered]: n === 0 })}>
            <div className={css.name}>
                {name}
            </div>
            <div className={css.side}>
                <span className={classNames({ [css.back]: side === Side.Back, [css.lay]: side === Side.Lay })}>{ side === Side.Back ? 'BACK': 'LAY' }</span>
            </div>
            <div className={css.count}>
                {n}
            { fn > 0 && <span className={css.filtered}>({fn})</span>}
            </div>
            <div className={css.sp}>{round(sp)}</div>
            <div className={css.price}>{round(price).toFixed(2)}</div>
            <div className={css.volume}>{round(vol).toFixed(2)}</div>
            <div className={classNames(css.profit, { [css.green]: profit>0, [css.red]: profit<0 })}>{ round(profit).toFixed(2) }</div>
        </div>
    );
}

type DateHeaderRowProps = {
    date: Date
    style: any
}
const DateHeaderRow = ({ date, style }: DateHeaderRowProps) => {
    return (
        <div className={css.dateRow} style={style}>
            { date.toDateString() }
        </div>
    );
}

const loadingVals = [ 
    LoadingRow, 
    1000, 
    {},
    i => i,
    () => 50,
]


export { HistoryList };