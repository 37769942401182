import React from 'react';
import Select from 'react-select';
import RadioInput from '../form/RadioInput';
import { Side, Filter, FilterOptions, FilterValue, FilterRange, PersistenceTypeStr, OrderTypeStr, InPlay, PersistenceType, OrderType  } from './Types'
import { DateRangeInput, RangeInput } from './HistoryRangeInput';

import * as style from "./HistoryFilter.module.scss";
import  "../form/FilterSelect.scss";
import CheckBox from '../form/CheckBox';


type HistoryFilterProps = {
    selected: Filter
    options: FilterOptions
    setFilter: (f: {selected: Filter, options: FilterOptions}) => void
}
const HistoryFilter = React.memo(({ selected, options, setFilter }: HistoryFilterProps) => {
    const { et, trk, types, rlid, str, per, ord, cc, mbr, mtRange } = options || {};

    // controls whether the expandable 'advanced' container is expanded
    // const expRef = useRef<HTMLDivElement>();
    // const [ contHeight, setContHeight ] = useState( expanded ? null : '0px'); 
    // useEffect(() => {
    //     expRef.current && setContHeight(expanded ? expRef.current.scrollHeight+'px' : '0px');
    // }, [contHeight, expanded]); // need expRef.current in deps?

    // whether there are filters applied in the un-expanded area
    // const hidden = !!(selected.s || selected.ord || selected.trk || selected.mbr || selected.per)

    // handler for the form element
    const isDisabled = (v: FilterValue) => v.disabled;
    const onPriceRangeChange = (value: FilterRange) => 
        setFilter({ options, selected: { ...selected, price: value }});

    const onDateRangeChange = (value: FilterRange) => 
        setFilter({ options, selected: { ...selected, mt: value }});

    const onRadioSideChange = (current: Side, value: Side) => 
        setFilter({ options, selected: { ...selected, s: current !== value ? value : undefined }});
    
    const onRadioInplayChange = (current: InPlay, value: InPlay) => 
        setFilter({ options, selected: { ...selected, ip: current !== value ? value : undefined }});

    const onCheckboxPersistChange = (value: PersistenceType) => {
        let next: PersistenceType[] = selected.per ? [ ...selected.per ]: [];

        const ind = next.findIndex(pt => pt == value);
        if (ind == -1) {
            next.push(value);
        } else {
            next.splice(ind, 1);
        }

        setFilter({ options, selected: { ...selected, per: next.length != 0 ? next: undefined }});
    }

    const onCheckboxOrderChange = (value: OrderType) => {
        let next: OrderType[] = selected.ord ? [ ...selected.ord ]: [];

        const ind = next.findIndex(ot => ot == value);
        if (ind == -1) {
            next.push(value);
        } else {
            next.splice(ind, 1);
        }

        setFilter({ options, selected: { ...selected, ord: next.length != 0 ? next: undefined }});
    }

    const onSelectChange = (value: FilterValue[], meta: {name: string}) => 
        setFilter({ options, selected: { ...selected, [meta.name]: (!value || value.length === 0) ? null : value.map(v => v.value) }});
    
    const limitChecked = Boolean(selected.ord?.includes(OrderType.Limit)), limitDisabled = Boolean(ord?.find( o => o.value == OrderType.Limit).disabled),
        limitSpChecked = Boolean(selected.ord?.includes(OrderType.LimitOnClose)), limitSpDisabled = Boolean(ord?.find( o => o.value == OrderType.LimitOnClose).disabled),
        mocChecked = Boolean(selected.ord?.includes(OrderType.MarketOnClose)), mocDisabled = Boolean(ord?.find( o => o.value == OrderType.MarketOnClose).disabled);

    const lapseChecked = Boolean(selected.per?.includes(PersistenceType.Lapse)), lapseDisabled = Boolean(per?.find( p => p.value == PersistenceType.Lapse).disabled),
        persistChecked = Boolean(selected.per?.includes(PersistenceType.Persist)), persistDisabled = Boolean(per?.find( p => p.value == PersistenceType.Persist).disabled),
        ptmocChecked = Boolean(selected.per?.includes(PersistenceType.MarketOnClose)), ptmocDisabled = Boolean(per?.find( p => p.value == PersistenceType.MarketOnClose).disabled);

    return (
        <div className={style.root}>

            <div className={style.row}>
                <div className={style.rowHeader}>Dates</div>
                <DateRangeInput 
                    range={mtRange}
                    defaultValue={selected.mt}
                    onChange={onDateRangeChange}
                />
            </div>

            <div className={style.rowCont}>
                <div className={style.rowContItem}>
                    <div className={style.rowHeader}>Side</div>
                    <div className={style.radios}>
                        <RadioInput  
                            name='side'
                            label='back' down
                            value={'B'}
                            onChange={() => {}}
                            onClick={() => onRadioSideChange(selected.s, Side.Back)}
                            checked={selected.s === Side.Back}
                        />
                        <div className={style.radiosDivider} />
                        <RadioInput            
                            name='side'
                            label='lay' down
                            value={'L'}
                            onChange={() => {}}
                            onClick={() => onRadioSideChange(selected.s, Side.Lay)}
                            checked={selected.s === Side.Lay}
                        />
                    </div>
                </div>
                <div className={style.rowContItem}>
                    <div className={style.rowHeader}>Placed</div>
                    <div className={style.radios}>
                        <RadioInput  
                            name='inplay'
                            label='pre-play' down
                            value={'2'}
                            onChange={() => {}}
                            onClick={() => onRadioInplayChange(selected.ip, InPlay.PrePlay)}
                            checked={selected.ip === InPlay.PrePlay}
                        />
                        <div className={style.radiosDivider} />
                        <RadioInput            
                            name='inplay'
                            label='in-play' down
                            value={'1'}
                            onChange={() => {}}
                            onClick={() => onRadioInplayChange(selected.ip, InPlay.InPlay)}
                            checked={selected.ip === InPlay.InPlay}
                        />
                    </div>
                </div>
            </div>


            <label className={style.row}>
                <div className={style.rowHeader}><span>Strategy Names</span></div>
                <Select isMulti
                    className='filter-select'
                    classNamePrefix='filter-select'
                    placeholder='all strategies'
                    onChange={onSelectChange}
                    isOptionDisabled={isDisabled}
                    maxMenuHeight={340}
                    isSearchable={false}
                    name='str'
                    value={selected?.str?.map( s => ({ label: s == '' ? 'No Strategy': s, value: s, disabled: false }))}
                    options={str?.map( opt => opt.label == '' ? ({ ...opt, label: 'No Strategy'}) : opt)}/>
            </label>
            
            <label className={style.row}>
                <div className={style.rowHeader}><span>Rule Names</span></div>
                <Select isMulti
                    className='filter-select'
                    classNamePrefix='filter-select'
                    placeholder='all rules'
                    onChange={onSelectChange}
                    isOptionDisabled={isDisabled}
                    maxMenuHeight={340}
                    isSearchable={false}
                    name='rlid'
                    value={selected?.rlid?.map( s => ({label: s == '' ? 'No Rule': s, value: s, disabled: false}))}
                    options={rlid?.map( opt =>  opt.label == '' ? ({ ...opt, label:'No Rule'}) : opt)}/>
            </label>
            
            <div className={style.row}>
                <div className={style.rowHeader}>Prices</div>
                <RangeInput 
                    range={{min: 1, max: 350}}
                    step={1}
                    defaultValue={selected?.price}
                    onChange={onPriceRangeChange}
                />
            </div>

            <label className={style.row}>
                <div className={style.rowHeader}><span>Event Types</span></div>
                <Select isMulti
                    className='filter-select'
                    classNamePrefix='filter-select'
                    placeholder='all event types'
                    onChange={onSelectChange}
                    isOptionDisabled={isDisabled}
                    maxMenuHeight={340}
                    isSearchable={false}
                    name='et'
                    value={selected?.et?.map( s => ({label: s == '' ? 'No Event Type' : s, value: s, disabled: false}))}
                    options={et?.map( opt => opt.label == '' ? ({ ...opt, label: 'No Event Type' }): opt )}/>
            </label>
            
            <label className={style.row}>
                <div className={style.rowHeader}><span>Countries</span></div>
                <Select isMulti
                    className='filter-select'
                    classNamePrefix='filter-select'
                    placeholder='all countries'
                    onChange={onSelectChange}
                    isOptionDisabled={isDisabled}
                    maxMenuHeight={340}
                    name='cc'
                    isSearchable={false}
                    // menuPlacement='top'
                    value={selected?.cc?.map( s => ({label: s == '' ? 'No Country': s, value: s, disabled: false}))}
                    options={cc?.map( opt => opt.label == '' ? ({ ...opt, label: 'No Country'}) : opt)}
                />
            </label>

            <label className={style.row}>
                <div className={style.rowHeader}><span>Tracks</span></div>
                <Select isMulti
                    className='filter-select'
                    classNamePrefix='filter-select'
                    placeholder='all tracks'
                    onChange={onSelectChange}
                    isOptionDisabled={isDisabled}
                    maxMenuHeight={340}
                    isSearchable={false}
                    name='trk'
                    value={selected?.trk?.map( s => ({label: s == '' ? 'No Track': s, value: s, disabled: false}))}
                    options={trk?.map( opt => opt.label == '' ? ({ ...opt, label: 'No Track'}): opt)}
                />
            </label>

            <label className={style.row}>
                <div className={style.rowHeader}><span>Market Types</span></div>
                <Select isMulti
                    className='filter-select'
                    classNamePrefix='filter-select'
                    placeholder='all types'
                    onChange={onSelectChange}
                    isOptionDisabled={isDisabled}
                    maxMenuHeight={340}
                    isSearchable={false}
                    name='types'
                    value={selected?.types?.map( s => ({label: s == '' ? 'No Type': s, value: s, disabled: false}))}
                    options={types?.map( opt => opt.label == '' ? ({ ...opt, label: 'No Type'}): opt)}
                />
            </label>

            <label className={style.row}>
                <div className={style.rowHeader}><span>Base Rates</span></div>
                <Select isMulti
                    className='filter-select'
                    classNamePrefix='filter-select'
                    placeholder='all base rates'
                    onChange={onSelectChange}
                    isOptionDisabled={isDisabled}
                    maxMenuHeight={340}
                    isSearchable={false}
                    name='mbr'
                    value={selected?.mbr?.map( s => ({label: s+'%', value: s, disabled: false}))}
                    options={mbr}
                />
            </label>
            
            <div className={style.rowCont}>
                <div className={style.rowContItem}>
                    <div className={style.rowHeader}>Persistence Types</div>
                    <div className={style.checkboxes}>
                        <CheckBox 
                            name={'ptl'} 
                            value={'1'} 
                            label='lapse' down
                            onChange={() => {}}
                            disabled={(lapseDisabled && !lapseChecked) || limitDisabled}
                            checked={lapseChecked}
                            onClick={() => onCheckboxPersistChange(PersistenceType.Lapse)}
                        />
                        <div className={style.checkboxesDivider} /> 
                        <CheckBox 
                            name={'ptp'} 
                            value={'1'}  
                            label='persist' down
                            onChange={() => {}}
                            disabled={(persistDisabled && !persistChecked) || limitDisabled}
                            checked={persistChecked}
                            onClick={() => onCheckboxPersistChange(PersistenceType.Persist)}
                        />
                        <div className={style.checkboxesDivider} /> 
                        <CheckBox 
                            name={'ptmoc'} 
                            value={'1'} 
                            label='sp' down
                            onChange={() => {}}
                            disabled={(ptmocDisabled && !ptmocChecked) || limitDisabled}
                            checked={ptmocChecked}
                            onClick={() => onCheckboxPersistChange(PersistenceType.MarketOnClose)}
                        />
                    </div>
                </div>
  
                <div className={style.rowContItem}>
                    <div className={style.rowHeader}>Order Types</div>
                    <div className={style.checkboxes}>
                        <CheckBox 
                            name={'otl'} 
                            value={'1'} 
                            label='limit' down
                            disabled={limitDisabled && !limitChecked}
                            onChange={() => {}}
                            checked={limitChecked}
                            onClick={() => onCheckboxOrderChange(OrderType.Limit)}
                        />
                        <div className={style.checkboxesDivider} />
                        <CheckBox 
                            name={'otloc'} 
                            value={'1'}  
                            label='limit sp' down
                            disabled={limitSpDisabled && !limitSpChecked}
                            onChange={() => {}}
                            checked={limitSpChecked}
                            onClick={() => onCheckboxOrderChange(OrderType.LimitOnClose)}
                        />
                        <div className={style.checkboxesDivider} /> 
                        <CheckBox 
                            name={'otmoc'} 
                            value={'1'} 
                            label='sp' down
                            disabled={mocDisabled && !mocChecked}
                            onChange={() => {}}
                            checked={mocChecked}
                            onClick={() => onCheckboxOrderChange(OrderType.MarketOnClose)}
                        />
                    </div>
                </div>
            </div>

        </div>
    );
})

export default HistoryFilter;
