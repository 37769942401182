
import './Style.scss';

export const colors = {
    green:'#98c379',
    red:'#e06c75',
    yellow:'#e5c07b',
    orange:'#d19a66',
    blue:'#09d3ac',
    cyan:'#09d3ac',
    magenta:'#c678dd',
};

export const axisStyle = {
    // line: {stroke: '#323842'},
    text: {stroke: 'none', fill: '#5c6370', fontWeight: 'bold', fontSize: 13, userSelect: 'none' }
} as React.CSSProperties;

export const vertAxisStyle = {
    text: { fill: '#5c6370',  fontSize: 13, fontWeight: 'bold', userSelect: 'none', mixBlendMode: 'difference' }
} as React.CSSProperties;

