import React from 'react';
import classNames from 'classnames';

const BetfairIcon = React.memo<{className?: string, [other: string]: any }>(({className, ...other}) => {
    return (
        <svg className={classNames('tb-icon', className)} 
            role="img" 
            viewBox="0 0 165 27" 
            height="1em"
            stroke="none"
            fill="currentColor"
            aria-labelledby="betfairTitle betfairDesc"
            { ...other } >
            <title id="betfairTitle">Betfair</title> 
            <desc id="betfairDesc">Betfair logo</desc> 
            <path d="M41.3 23.9c1.2 1.5 3.3 2.3 5.9 2.3h1.3c5.4 0 9.3-1.4 9.3-7.6v-3.8c0-5.7-2.5-7.8-9.1-7.8h-2c-2.9 0-4.5 1-5.2 2h-.1V0h-4v25.6h4v-1.7zm0-9.2c0-4.3 2.7-4.4 6.4-4.4h.4c3.6 0 5.6.6 5.6 4.8V18c0 3.5-.9 5-5.7 5h-.9c-4.6 0-5.8-1.3-5.8-4.7v-3.6zM106.7 7.6h-5.4V5.9c0-1.6 1-2.8 3.2-2.8h4V0h-5.4c-3.2 0-5.9 1.7-5.9 5.9v1.6H95v3.2h2.2v15.4h4V10.8h5.4V7.6zM83.3 20.3c0 4.3 2.7 5.9 6 5.9h5V23h-4c-2.2 0-2.9-1.1-2.9-2.7v-9.5h5.4V7.6h-5.4V0h-4v7.6h-2.2v3.2h2.2l-.1 9.5zM129.8 0h4v4.1h-4zM129.8 7.1h4v19.1h-4zM68.2 26.2h3.6c4.9 0 7.1-1.6 7.7-4.3l-3.6-1.1c-.6 1.7-2 2.1-5.2 2.1h-.9c-5.2 0-6-.7-6-4.7h16v-3.5c0-6.4-3.3-7.6-8.6-7.6h-2c-4.2 0-9.3.7-9.3 7.2v4.5c0 6.4 4.4 7.4 8.3 7.4zm-4.4-11.8c0-3.7 1.7-4 5.9-4h.8c4.7 0 5.3 1.2 5.3 3.6v1h-12v-.6zM117.2 7.1h-1.5c-5.5 0-7.8 1.7-8 4.2l3.8 1.3c.2-1.7 2.2-2.2 5.2-2.2 4.5 0 5.5.3 5.5 3.5v1.8h-.1c-1-1.3-2.4-1.7-5.9-1.7h-1.6c-5.2 0-8.2 1.3-8.2 5.4v1.2c0 3.4 1.3 5.8 6.7 5.8h3.9c3.9 0 4.3-.6 5.1-1.8h.1v1.7h4V13.9c0-5.3-3.5-6.8-9-6.8zm5 13.4c0 1.8-1.2 2.5-6 2.5h-1.1c-4.1 0-4.6-.8-4.6-2.3v-1c0-1.8 1-2.5 4.4-2.5h1.8c3.1 0 5.5.6 5.5 2.6v.7zM146.2 7.1c-1.8 0-3.6.7-4.8 2.5h-.1V7.1h-3.8v19.1h4V14.7c0-2.3 1.3-4.4 4.5-4.4 2.2 0 3.3.6 3.6 1.2l2.5-1.7c-.7-1.6-2.4-2.7-5.9-2.7zM29.8.1H19.4v5.2h-5.6l10.8 12.6L35.4 5.3h-5.6zM0 20.8h5.6v5.3H16v-5.3h5.6L10.8 8.2z"></path>
        </svg>
    )
});


const KeyIcon = React.memo<{className?: string, [other: string]: any }>(({className, ...other}) => {
    return (
        <svg className={classNames('tb-icon', className)} 
            role="img" 
            xmlns="http://www.w3.org/2000/svg" 
            width="1em" 
            height="1em" 
            viewBox="0 0 24 24" 
            aria-labelledby="keyIconTitle keyIconDesc" 
            stroke="currentColor"
            strokeWidth="2" 
            strokeLinecap="square" 
            strokeLinejoin="miter" 
            fill="none"
            { ...other} > 
            <title id="keyIconTitle">Key</title> 
            <desc id="keyIconDesc">Icon of a key</desc> 
            <polyline points="21 16 21 12 12 12"/> 
            <circle cx="7" cy="12" r="4"/> 
            <path d="M17,15 L17,12"/> 
        </svg>
    )
});

const ShieldIcon = React.memo<{className?: string, [other: string]: any }>(({className, ...other}) => {
    return (
        <svg className={classNames('tb-icon', className)} 
            role="img" 
            xmlns="http://www.w3.org/2000/svg" 
            width="1em" 
            height="1em" 
            viewBox="0 0 24 24" 
            aria-labelledby="shieldIconTitle shieldIconDesc" 
            stroke="currentColor" 
            strokeWidth="2" 
            strokeLinecap="square" 
            strokeLinejoin="miter" 
            fill="none"
            { ...other} > 
            <title id="shieldIconTitle">Shield</title>
            <desc id="shieldIconDesc">Icon of a medieval shield</desc>
            <path d="M19,14.7368421 C19,17.122807 16.6666667,19.2105263 12,21 C7.33333333,19.2105263 5,17.122807 5,14.7368421 C5,12.3508772 5,9.36842105 5,5.78947368 C8.13611482,4.59649123 10.4694481,4 12,4 C13.5305519,4 15.8638852,4.59649123 19,5.78947368 C19,9.36842105 19,12.3508772 19,14.7368421 Z"/>
        </svg>
    )
});

const DollarIcon = React.memo<{className?: string, [other: string]: any }>(({className, ...other}) => {
    return (
        <svg className={classNames('tb-icon', className)} 
            role="img" 
            xmlns="http://www.w3.org/2000/svg" 
            width="1em" 
            height="1em" 
            viewBox="0 0 24 24" 
            aria-labelledby="dolarIconTitle dolarIconDesc" 
            stroke="currentColor" 
            strokeWidth="2" 
            strokeLinecap="square" 
            strokeLinejoin="miter" 
            fill="none" 
            { ...other}> 
            <title id="dolarIconTitle">Dolar</title>
            <desc id="dolarIconDesc">Icon of a dolar sign</desc>
            <path d="M12 4L12 6M12 18L12 20M15.5 8C15.1666667 6.66666667 14 6 12 6 9 6 8.5 7.95652174 8.5 9 8.5 13.140327 15.5 10.9649412 15.5 15 15.5 16.0434783 15 18 12 18 10 18 8.83333333 17.3333333 8.5 16"/>
        </svg>
    )
});

const GlobeIcon = React.memo<{className?: string, [other: string]: any }>(({className, ...other}) => {
    return (
        <svg className={classNames('tb-icon', className)} 
            role="img" 
            xmlns="http://www.w3.org/2000/svg" 
            width="1em" 
            height="1em" 
            viewBox="0 0 24 24" 
            aria-labelledby="languageIconTitle languageIconDesc" 
            stroke="currentColor" 
            strokeWidth="2" 
            strokeLinecap="square" 
            strokeLinejoin="miter" 
            fill="none" 
            { ...other} > 
            <title id="languageIconTitle">Language</title> 
            <desc id="languageIconDesc">Icon of a globe</desc> 
            <circle cx="12" cy="12" r="10"/> 
            <path strokeLinecap="round" d="M12,22 C14.6666667,19.5757576 16,16.2424242 16,12 C16,7.75757576 14.6666667,4.42424242 12,2 C9.33333333,4.42424242 8,7.75757576 8,12 C8,16.2424242 9.33333333,19.5757576 12,22 Z"/> 
            <path strokeLinecap="round" d="M2.5 9L21.5 9M2.5 15L21.5 15"/>
        </svg>
    )
});

const ControlsIcon = React.memo<{className?: string, [other: string]: any }>(({className, ...other}) => {
    return (
        <svg className={classNames('tb-icon', className)} 
            role="img" 
            xmlns="http://www.w3.org/2000/svg" 
            width="1em" 
            height="1em" 
            viewBox="0 0 24 24" 
            aria-labelledby="controlsIconTitle controlsIconDesc" 
            stroke="currentColor" 
            strokeWidth="2" 
            strokeLinecap="square" 
            strokeLinejoin="miter" 
            fill="none"
            { ...other} >
            <title id="controlsIconTitle">Controllers</title> 
            <desc id="controlsIconDesc">Icon of three vertical slide controllers</desc> 
            <path d="M17 18L17 6M12 18L12 6M7 18L7 6M5 8L9 8M10 16L14 16M15 12L19 12"/> 
        </svg>
    )
});

const WriteIcon = React.memo<{className?: string, [other: string]: any }>(({className, ...other}) => {
    return(
        <svg className={classNames('tb-icon', className)} 
            role="img" 
            xmlns="http://www.w3.org/2000/svg" 
            width="1em" 
            height="1em" 
            viewBox="0 0 24 24" 
            aria-labelledby="newIconTitle newIconDesc" 
            stroke="currentColor" 
            strokeWidth="2" 
            strokeLinecap="square" 
            strokeLinejoin="miter" 
            fill="none"
            { ...other}>
            <title id="newIconTitle">New</title>
            <desc id="newIconDesc">Icon of blank page with a pencil</desc>
            <path d="M19,13 L19,20 C19,20.5522847 18.5522847,21 18,21 L4,21 C3.44771525,21 3,20.5522847 3,20 L3,4 C3,3.44771525 3.44771525,3 4,3 L13,3"/> 
            <path d="M21.2892136 2.03921356L21.9607864 2.71078644C22.741835 3.49183502 22.741835 4.75816498 21.9607864 5.53921356L12 15.5 8.5 15.5 8.5 12 18.4607864 2.03921356C19.241835 1.25816498 20.508165 1.25816498 21.2892136 2.03921356zM17.25 3.25L20.75 6.75"/> 
        </svg>
    );
});

const SmallCrossIcon = React.memo<{className?: string, [other: string]: any }>(({className, ...other}) => {
    return(
        <svg className={classNames('tb-icon', className)}
            height="1em"
            width="1em"
            fill="currentColor" 
            stroke="currentColor" 
            viewBox="0 0 20 20"
            aria-hidden="true"
            focusable="false"
            { ...other} >
            <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
        </svg>
    );
});

const CrossIcon = React.memo<{className?: string, [other: string]: any }>(({ className, ...other }) => {
    return(
        <svg className={classNames('tb-icon', className)}
            height="1em"
            width="1em"
            fill="currentColor" 
            stroke="currentColor" 
            focusable="false" 
            viewBox="0 0 24 24" 
            aria-hidden="true" 
            role="presentation"
            { ...other}>
            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
        </svg>

    );
});

const TickIcon = React.memo<{className?: string, [other: string]: any }>(({className, ...other}) => {
    return(
        <svg 
            className={classNames('tb-icon', className)}
            height="1em"
            width="1em"
            fill="currentColor" 
            stroke="currentColor"
            focusable="false" 
            viewBox="0 0 24 24" 
            aria-hidden="true" 
            role="presentation"
            { ...other}>
            <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" />
        </svg>
    );
});

const RefreshIcon = React.memo<{className?: string, [other: string]: any }>(({className, ...other}) => {
    return(
        <svg 
            className={classNames('tb-icon', className)}
            height="1em"
            width="1em"
            fill="currentColor" 
            stroke="currentColor"
            focusable="false" 
            viewBox="0 0 24 24" 
            aria-hidden="true" 
            role="presentation"
            { ...other}>
            <path d="M12 4V1L8 5l4 4V6c3.31 0 6 2.69 6 6 0 1.01-.25 1.97-.7 2.8l1.46 1.46C19.54 15.03 20 13.57 20 12c0-4.42-3.58-8-8-8zm0 14c-3.31 0-6-2.69-6-6 0-1.01.25-1.97.7-2.8L5.24 7.74C4.46 8.97 4 10.43 4 12c0 4.42 3.58 8 8 8v3l4-4-4-4v3z"></path>
        </svg>
    );
});

const GraphIcon = React.memo<{className?: string, [other: string]: any }>(({className, ...other}) => {
    return(
        <svg 
            className={classNames('tb-icon', className)}
            height="1em"
            width="1em"
            fill="currentColor" 
            stroke="currentColor"
            focusable="false" 
            viewBox="0 0 24 24" 
            aria-hidden="true" 
            role="presentation"
            { ...other}>
           <path d="M10 20h4V4h-4v16zm-6 0h4v-8H4v8zM16 9v11h4V9h-4z"></path>
        </svg>
    );
});

const LockIcon = React.memo<{className?: string, [other: string]: any }>(({className, ...other}) => {
    return(
        <svg 
            className={classNames('tb-icon', className)}
            height="1em"
            width="1em"
            fill="currentColor" 
            stroke="currentColor"
            focusable="false" 
            viewBox="0 0 24 24" 
            aria-hidden="true" 
            role="presentation"
            { ...other}>
            <path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z"></path>
        </svg>
    );
});

const UserIcon = React.memo<{className?: string, [other: string]: any }>(({className, ...other}) => {
    return(
        <svg 
            className={classNames('tb-icon', className)}
            height="1em"
            width="1em"
            fill="currentColor" 
            stroke="currentColor"
            focusable="false" 
            viewBox="0 0 24 24" 
            aria-hidden="true" 
            role="presentation"
            { ...other}>
            <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"></path>
        </svg>
    );
});

const ErrorIcon = React.memo<{className?: string, [other: string]: any }>(({className, ...other}) => {
    return(
        <svg 
            className={classNames('tb-icon', className)}
            height="1em"
            width="1em"
            fill="currentColor" 
            stroke="none"
            focusable="false" 
            viewBox="0 0 24 24" 
            aria-hidden="true" 
            role="presentation"
            { ...other}>
            <path fill="none" d="M0 0h24v24H0V0z"></path>
            <path d="M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path>
        </svg>
    );
});

const NextIcon = React.memo<{className?: string, [other: string]: any }>(({className, ...other}) => {
    return(
        <svg 
            className={classNames('tb-icon', className)}
            height="1em"
            width="1em"
            fill="currentColor" 
            stroke="none"
            focusable="false" 
            viewBox="0 0 24 24" 
            aria-hidden="true" 
            role="presentation"
            { ...other}>
            <path d="M6 18l8.5-6L6 6v12zM16 6v12h2V6h-2z"></path>
        </svg>
    );
});


const PowerIcon = React.memo<{className?: string, [other: string]: any }>(({className, ...other}) => {
    return(
        <svg 
            className={classNames('tb-icon', className)}
            height="1em"
            width="1em"
            fill="currentColor" 
            stroke="none"
            focusable="false" 
            viewBox="0 0 24 24" 
            aria-hidden="true" 
            role="presentation"
            { ...other}>
            <path d="M13 3h-2v10h2V3zm4.83 2.17l-1.42 1.42C17.99 7.86 19 9.81 19 12c0 3.87-3.13 7-7 7s-7-3.13-7-7c0-2.19 1.01-4.14 2.58-5.42L6.17 5.17C4.23 6.82 3 9.26 3 12c0 4.97 4.03 9 9 9s9-4.03 9-9c0-2.74-1.23-5.18-3.17-6.83z"></path>
        </svg>
    );
});


const LowPriorityIcon = React.memo<{className?: string, [other: string]: any }>(({className, ...other}) => {
    return(
        <svg 
            className={classNames('tb-icon', className)}
            height="1em"
            width="1em"
            fill="currentColor" 
            stroke="none"
            focusable="false" 
            viewBox="0 0 24 24" 
            aria-hidden="true" 
            role="presentation"
            { ...other}>
            <path d="M14 5h8v2h-8zm0 5.5h8v2h-8zm0 5.5h8v2h-8zM2 11.5C2 15.08 4.92 18 8.5 18H9v2l3-3-3-3v2h-.5C6.02 16 4 13.98 4 11.5S6.02 7 8.5 7H12V5H8.5C4.92 5 2 7.92 2 11.5z"/><path fill="none" d="M0 0h24v24H0z"/>
        </svg>
    );
});

const FolderIcon = React.memo<{className?: string, [other: string]: any }>(({className, ...other}) => {
    return(
        <svg 
            className={classNames('tb-icon', className)}
            height="1em"
            width="1em"
            fill="currentColor" 
            stroke="none"
            focusable="false" 
            viewBox="0 0 24 24" 
            aria-hidden="true" 
            role="presentation"
            { ...other}>
            <path fill="none" d="M0 0h24v24H0V0z" /><path d="M9.17 6l2 2H20v10H4V6h5.17M10 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2h-8l-2-2z" />
        </svg>
    );
});

const FileIcon = React.memo<{className?: string, [other: string]: any }>(({className, ...other}) => {
    return(
        <svg 
            className={classNames('tb-icon', className)}
            height="1em"
            width="1em"
            fill="currentColor" 
            stroke="none"
            focusable="false" 
            viewBox="0 0 24 24" 
            aria-hidden="true" 
            role="presentation"
            { ...other}>
            <path fill="none" d="M0 0h24v24H0V0z" /><path d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zM6 20V4h7v5h5v11H6z" />
        </svg>
    );
});

const ArrowUpIcon = React.memo<{className?: string, [other: string]: any }>(({className, ...other}) => {
    return(
        <svg 
            className={classNames('tb-icon', className)}
            height="1em"
            width="1em"
            fill="currentColor" 
            stroke="none"
            focusable="false" 
            viewBox="0 0 24 24" 
            aria-hidden="true" 
            role="presentation"
            { ...other}>
            <path fill="none" d="M0 0h24v24H0V0z" /><path d="M4 12l1.41 1.41L11 7.83V20h2V7.83l5.58 5.59L20 12l-8-8-8 8z" />
        </svg>
    );
});

const ChevronDownIcon = React.memo<{className?: string, [other: string]: any }>(({className, ...other}) => {
    return(
        <svg 
            className={classNames('tb-icon', className)}
            height="1em"
            width="1em"
            fill="currentColor" 
            stroke="none"
            focusable="false" 
            viewBox="0 0 20 20" 
            aria-hidden="true" 
            role="presentation"
            { ...other}>
            <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
        </svg>
    );
});

const ChevronRightIcon = React.memo<{className?: string, [other: string]: any }>(({className, ...other}) => {
    return(
        <svg 
            className={classNames('tb-icon', className)}
            height="1em"
            width="1em"
            fill="currentColor" 
            stroke="none"
            focusable="false" 
            viewBox="0 0 20 20" 
            aria-hidden="true" 
            role="presentation"
            { ...other}>
            <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"/>
        </svg>
    );
});

const ChevronLeftIcon = React.memo<{className?: string, [other: string]: any }>(({className, ...other}) => {
    return(
        <svg 
            className={classNames('tb-icon', className)}
            height="1em"
            width="1em"
            fill="currentColor" 
            stroke="none"
            focusable="false" 
            viewBox="0 0 20 20" 
            aria-hidden="true" 
            role="presentation"
            { ...other}>
            <path d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"/>
        </svg>
    );
});

const UploadIcon = React.memo<{className?: string, [other: string]: any }>(({className, ...other}) => {
    return(
        <svg 
            className={classNames('tb-icon', className)}
            height="1em"
            width="1em"
            fill="currentColor" 
            stroke="none"
            focusable="false" 
            viewBox="0 0 24 24" 
            aria-hidden="true" 
            role="presentation"
            { ...other}>
            <path d="M0 0h24v24H0z" fill="none"/>
            <path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z"/>
        </svg>
    );
});

const SettingsIcon = React.memo<{className?: string, [other: string]: any }>(({className, ...other}) => {
    return(
        <svg 
            className={classNames('tb-icon', className)}
            height="1em"
            width="1em"
            fill="currentColor" 
            stroke="none"
            focusable="false" 
            viewBox="0 0 24 24" 
            aria-hidden="true" 
            role="presentation"
            { ...other}>
            <g>
                <path d="M0,0h24v24H0V0z" fill="none"/>
                <path d="M19.14,12.94c0.04-0.3,0.06-0.61,0.06-0.94c0-0.32-0.02-0.64-0.07-0.94l2.03-1.58c0.18-0.14,0.23-0.41,0.12-0.61 l-1.92-3.32c-0.12-0.22-0.37-0.29-0.59-0.22l-2.39,0.96c-0.5-0.38-1.03-0.7-1.62-0.94L14.4,2.81c-0.04-0.24-0.24-0.41-0.48-0.41 h-3.84c-0.24,0-0.43,0.17-0.47,0.41L9.25,5.35C8.66,5.59,8.12,5.92,7.63,6.29L5.24,5.33c-0.22-0.08-0.47,0-0.59,0.22L2.74,8.87 C2.62,9.08,2.66,9.34,2.86,9.48l2.03,1.58C4.84,11.36,4.8,11.69,4.8,12s0.02,0.64,0.07,0.94l-2.03,1.58 c-0.18,0.14-0.23,0.41-0.12,0.61l1.92,3.32c0.12,0.22,0.37,0.29,0.59,0.22l2.39-0.96c0.5,0.38,1.03,0.7,1.62,0.94l0.36,2.54 c0.05,0.24,0.24,0.41,0.48,0.41h3.84c0.24,0,0.44-0.17,0.47-0.41l0.36-2.54c0.59-0.24,1.13-0.56,1.62-0.94l2.39,0.96 c0.22,0.08,0.47,0,0.59-0.22l1.92-3.32c0.12-0.22,0.07-0.47-0.12-0.61L19.14,12.94z M12,15.6c-1.98,0-3.6-1.62-3.6-3.6 s1.62-3.6,3.6-3.6s3.6,1.62,3.6,3.6S13.98,15.6,12,15.6z"/>
            </g>
        </svg>
    );
});

const DashboardIcon = React.memo<{className?: string, [other: string]: any }>(({className, ...other}) => {
    return(
        <svg 
            className={classNames('tb-icon', className)}
            height="1em"
            width="1em"
            fill="currentColor" 
            stroke="none"
            focusable="false" 
            viewBox="0 0 24 24" 
            aria-hidden="true" 
            role="presentation"
            { ...other}>
           <path d="M0 0h24v24H0z" fill="none"/>
           <path d="M10 18h5v-6h-5v6zm-6 0h5V5H4v13zm12 0h5v-6h-5v6zM10 5v6h11V5H10z"/>
        </svg>
    );
});

const ListIcon = React.memo<{className?: string, [other: string]: any }>(({className, ...other}) => {
    return(
        <svg 
            className={classNames('tb-icon', className)}
            height="1em"
            width="1em"
            fill="currentColor" 
            stroke="none"
            focusable="false" 
            viewBox="0 0 24 24" 
            aria-hidden="true" 
            role="presentation"
            { ...other}>
            <path d="M0 0h24v24H0z" fill="none"/>
            <path d="M2 21h19v-3H2v3zM20 8H3c-.55 0-1 .45-1 1v6c0 .55.45 1 1 1h17c.55 0 1-.45 1-1V9c0-.55-.45-1-1-1zM2 3v3h19V3H2z"/>
        </svg>
    );
});

export {
    BetfairIcon,
    ArrowUpIcon,
    SettingsIcon,
    ListIcon,
    DashboardIcon,
    ChevronDownIcon,
    ChevronRightIcon,
    ChevronLeftIcon,
    FileIcon,
    FolderIcon,
    TickIcon,
    CrossIcon,
    SmallCrossIcon,
    KeyIcon,
    LowPriorityIcon,
    ShieldIcon,
    DollarIcon,
    GlobeIcon,
    ControlsIcon,
    WriteIcon,
    GraphIcon,
    RefreshIcon,
    LockIcon,
    UserIcon,
    ErrorIcon,
    NextIcon,
    PowerIcon,
    UploadIcon,
}