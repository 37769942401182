import React from 'react';
import classNames from 'classnames';

import RModal from 'react-modal';

import style from './Modal.module.scss';

type ModalProps = {
    isOpen: boolean
    onClose: () => void
    label?: string
    className?: string
    children: React.ReactNode
}
const Modal = ({ isOpen, onClose, label, className, children }: ModalProps) => {

    return (
    <RModal
        isOpen={isOpen}
        onRequestClose={onClose}
        contentLabel={label}
        className={classNames(style.modal, className)}
        ariaHideApp={false}
        bodyOpenClassName={style.bodyModal}
        overlayClassName={style.overlay} >
            { children }
    </RModal>);
}

export default Modal;