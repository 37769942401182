import React, { useState } from 'react';
import classNames from 'classnames';
import { AutoSizer } from 'react-virtualized';
import {  WidthHeight } from '../Types'
import { Button } from '../../form/Buttons'

import style from './Container.module.scss';


type Item = {
    label: string
    disabled: boolean
    render: (wh: WidthHeight) => void
}

type GraphContainerProps = {
    title: string
    items: Item[]
    loading: boolean
}
export const GraphContainer = React.memo(({ title, items, loading }: GraphContainerProps) => {
    const [ index, setIndex ] = useState(1);
    const current = items[index];

    return (
        <div className={classNames(style.root, { [style.loading]: loading })} >
            <div className={style.toprow}>
                <div className={style.title}>{ title }</div>
                
                <div className={style.selects}>
                    { items.map( (item, i) => 
                        <Button 
                            key={i}
                            disabled={item.disabled}
                            active={i===index}
                            onClick={() => setIndex(i)}
                        >{ item.label }</Button>  
                    )}
                </div>
            </div>
            <AutoSizer>{wh => current.render(wh) }</AutoSizer>
        </div>
    );
});