import React from 'react';
import classNames from 'classnames';
import * as style from "./RadioInput.module.scss";

/*
    Radio Input - material-ish radio
*/
type RadioInputProps = {
    name: string
    value: string
    label: string
    checked: boolean
    onChange: () => void
    onClick: () => void
    left?: boolean
    down?: boolean
    right?: boolean
    [other: string]: any
}
const RadioInput = ({name, value, label, checked, onChange, onClick, left, down, right, ...other }: RadioInputProps) => 
    <label className={classNames(style.radioInput, {[style.left]:left, [style.down]:down, [style.right]:right})} { ...other } >
        <input name={name} type="radio" value={value} checked={checked} onChange={onChange} onClick={onClick} />
        { label && <span className={style.radioInputLabel}>{label}</span> }
        <span className={style.radioInputBack}></span>
    </label>
    
export default RadioInput;