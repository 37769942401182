import React, { useState } from 'react';
import { XYPlot, XAxis, YAxis, Crosshair } from 'react-vis';
import { DayResult  } from '.././Types'
import { monthString, dateWithSuffix } from '../../_Funcs';
import classNames from 'classnames';


import { CandleStickSeries } from './CandleStick'
import { colors, axisStyle, vertAxisStyle } from './Style'

import style from './CandleStickGraph.module.scss';


type CandleStickGraphProps = {
    data: DayResult[]
    width: number
    height: number
}
export const CandleStickGraph = React.memo(({ data, width, height }: CandleStickGraphProps) => {
    const [ closest, setClosest ] = useState<DayResult>(null);
    const [ hintPos, setHintPos ] = useState<number>(null);
    const [ yMin, yMax ] = data.reduce( (minMax, v) => {
        minMax[0] = Math.min(minMax[0], v.low);
        minMax[1] = Math.max(minMax[1], v.high);
        return minMax;
    }, [5, -5]);

    return (
        <div className={style.root}>
            <XYPlot 
                animation 
                yDomain={[yMin, yMax]}
                xDomain={[0, Math.max(data.length + 1, 35)]}
                height={height} 
                width={width}
                onMouseLeave={ () => setClosest(null) } >
          
                { closest && 
                <Crosshair 
                    // innerWidth={8}
                    values={[ closest ]} 
                    marginLeft={60}
                    style={{ line: { backgroundColor: colors.blue }}}
                    >
                    <div />
                </Crosshair>}

                <CandleStickSeries
                    animation
                    // className={''}
                    marginLeft={60}
                    marginTop={0}
                    data={data}
                    onNearestX={ (dr: any, p: any) =>{
                        setClosest(dr as DayResult);
                        setHintPos(Math.min(Math.max(p.innerX, 50), width-55-70));
                    }}
                />

                <YAxis 
                    tickSize={0}
                    width={40}
                    style={axisStyle}
                />

                {/* <XAxis 
                    tickSize={-15}
                    tickTotal={ data.length }
                    tickLabelAngle={90}
                    tickFormat={ (i: number) => {
                        const ts =data[i]?.date
                        if (!ts) return '';

                        const d = new Date(ts);
                        return `${d.getDate()}/${d.getMonth()}` 
                    }}
                    marginLeft={60}
                    style={ vertAxisStyle } /> */}

            
            </XYPlot>
            { closest &&
                <div className={style.hint}
                    style={{left: hintPos-60}}>
                    <div className={style.title}>{dateString(closest.date)}</div>
                    <div className={style.row}>
                        <span className={style.text}>net:</span><span className={classNames(style.fig, { [style.red]: closest.open > closest.close, [style.green]: closest.open < closest.close })}>{(closest.close-closest.open).toFixed(2)}</span>
                    </div>
                    <div className={style.row}>
                        <span className={style.text}>open:</span><span className={style.fig}>{ closest.open.toFixed(2)}</span>
                    </div>
                    <div className={style.row}>
                        <span className={style.text}>close:</span><span className={style.fig}>{ closest.close.toFixed(2)}</span>
                    </div>
                    <div className={style.row}>
                        <span className={style.text}>low:</span><span className={style.fig}>{ closest.low.toFixed(2)}</span>
                    </div>
                    <div className={style.row}>
                        <span className={style.text}>high:</span><span className={style.fig}>{ closest.high.toFixed(2)}</span>
                    </div>
            </div> }
            
        </div>
    );
});

const dateString = (ts: number): string => {
    const d = new Date(ts);
    return `${dateWithSuffix(d.getDate())} ${monthString(d.getMonth())}`
} 