import React from 'react';
import classNames from 'classnames';

import { ProgressButton2 } from '../form/Buttons';
import { RefreshIcon} from '../Icons';
import { DropZone } from './HistoryConfig';

import chartImg from '../../images/barchart.svg';

import style from './HistoryWelcome.module.scss';


type HistoryWelcomeProps = {
    importJSONBlob: (data: Blob, progress?: (progress: any) => boolean) => Promise<any>
    update: () => Promise<void>
    fetchItems: () => Promise<void>
}
export const HistoryWelcome = ({ importJSONBlob, update, fetchItems }: HistoryWelcomeProps) => {
    return (
        <div className={classNames(style.prompt, style.modal)}>
            <img className={style.img} src={chartImg} />
            <h4 className={style.title}>Profit and Loss</h4>

            <div className={style.text}>You have no bet history stored in this browsers database</div>
            <div className={style.text}>To get started you can update with the latest 3 months of your data from Betfair or you can import a previously saved database backup file</div>
                
            <div className={style.body}>
                <div className={style.bodyLeft}>
                    <ProgressButton2 
                        className='blue'
                        onClick={fetchItems} 
                        pendingTxt={'updating'}
                        successTxt={'done'}
                        errorTxt={'error'}
                        icon={<RefreshIcon />}
                        text={'update'}
                    />
                </div>
                <div className={style.bodyRight}>
                    <DropZone importJSONBlob={importJSONBlob} update={update} 
                        hint={<p className={style.hint}>Import Backup</p>}
                        done={<p className={style.hint}>imported</p>} />
                </div>
            </div>
            <div className={style.footer}>
                
            </div>
        </div>);
}